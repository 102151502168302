import React from "react";
import { useTranslation } from "react-i18next"; // Import the useTranslation hook
import i18n from "i18next";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { TaxonomyType } from "data/types";
import SectionHero from "../../containers/PageAbout/SectionHero";
import SectionHero3 from "components/SectionHero/SectionHero3";
import CardCategory6 from "components/CardCategory6/CardCategory6";
import holidayType from "images/holidayType.png";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import antalyaJpg from "images/antalya1.jpg";
import cundaJpg from "images/cunda1.jpg";
import trabzonJpg from "images/trabzon1.jpg";
import istanbulJpg from "images/istanbul1.jpg";
import cappadociaJpg from "images/cappadocia1.jpg";
import russiaJpg from "images/russia.jpg";         
import mostarJpg from "images/mostar1.jpg";         
import trekkingJpg from "images/trekking1.jpg";
import gastronomyPng from "images/gastronomy1.png";
import festivalJpg from "images/festival1.jpg";
import compassPng from "images/compass1.png";
import hikingJpg from "images/hiking1.jpg";         
import worldmapPainting from "images/worldmappainting1.png"; 
import honeymoonPng from "images/honeymoon.png";  
import transferPng from "images/transfer1.png";           
import micePng from "images/mice.png";             
import woodenFamilyPng from "images/woodenFamily.png";
import SectionOurFeatures from "components/SectionOurFeatures/SectionOurFeatures";
import SectionStatistic from "./SectionStatistic";

const DEMO_CATS_YURTICI: TaxonomyType[] = [
  {
    id: "1",
    href: "/Antalya_tour_6_7",
    name: "antalya",
    taxonomy: "category",
    count: 188288,
    thumbnail: antalyaJpg,
  },
  {
    id: "2",
    href: "/Northern_aegean_tour_6_7",
    name: "northernAegean",
    taxonomy: "category",
    count: 188288,
    thumbnail: cundaJpg,
  },
  {
    id: "3",
    href: "/Eastern_black_sea_tour_6_7",
    name: "easternBlackSea",
    taxonomy: "category",
    count: 188288,
    thumbnail: trabzonJpg,
  },
  {
    id: "4",
    href: "/Istanbul_tour",
    name: "istanbul",
    taxonomy: "category",
    count: 188288,
    thumbnail: istanbulJpg,
  },
  {
    id: "5",
    href: "/Cappadocia_tour",
    name: "Cappadocia",
    taxonomy: "category",
    count: 188288,
    thumbnail: cappadociaJpg,
  },
  {
    id: "6",
    href: "/Western_black_sea_tour_5_6",
    name: "westernBlackSea",
    taxonomy: "category",
    count: 188288,
    thumbnail: "https://images.unsplash.com/photo-1696149333862-6999ecb65d0e?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
];

const DEMO_CATS_YURTDISI: TaxonomyType[] = [
  {
    id: "1",
    href: "/moscow_st_petersburg",
    name: "russia",
    taxonomy: "category",
    count: 188288,
    thumbnail: russiaJpg,
  },
  {
    id: "2",
    href: "/balkans",
    name: "balkans",
    taxonomy: "category",
    count: 188288,
    thumbnail: mostarJpg,
  },
  {
    id: "3",
    href: "/morocco",
    name: "morocco",
    taxonomy: "category",
    count: 188288,
    thumbnail: "https://images.unsplash.com/photo-1564507004663-b6dfb3c824d5?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    id: "4",
    href: "/greece",
    name: "greece",
    taxonomy: "category",
    count: 188288,
    thumbnail: "https://images.pexels.com/photos/3224232/pexels-photo-3224232.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  // {
  //   id: "5",
  //   href: "/listing-stay",
  //   name: "Japan",
  //   taxonomy: "category",
  //   count: 188288,
  //   thumbnail: tokyoJpg,
  // },
  // {
  //   id: "6",
  //   href: "/listing-stay",
  //   name: "Paris",
  //   taxonomy: "category",
  //   count: 188288,
  //   thumbnail: parisJpg,
  // },
];

const DEMO_CATS_2: TaxonomyType[] = [
  {
    id: "1",
    href: "",
    name: "gastronomy",
    taxonomy: "category",
    count: 188288,
    thumbnail: gastronomyPng,
  },
  {
    id: "2",
    href: "",
    name: "walkingTrekking",
    taxonomy: "category",
    count: 188288,
    thumbnail: hikingJpg,
  },
  {
    id: "3",
    href: "",
    name: "festivals",
    taxonomy: "category",
    count: 188288,
    thumbnail: festivalJpg,
  },
  {
    id: "4",
    href: "",
    name: "tailorMade",
    taxonomy: "category",
    count: 188288,
    thumbnail: worldmapPainting,
  },
  {
    id: "5",
    href: "",
    name: "seasonalTours",
    taxonomy: "category",
    count: 188288,
    thumbnail: compassPng,
  },
  {
    id: "6",
    href: "",
    name: "family",
    taxonomy: "category",
    count: 188288,
    thumbnail: woodenFamilyPng,
  },
  {
    id: "7",
    href: "",
    name: "privateGroups",
    taxonomy: "category",
    count: 188288,
    thumbnail: trekkingJpg,
  },
  {
    id: "8",
    href: "",
    name: "honeymoon",
    taxonomy: "category",
    count: 0,
    thumbnail: honeymoonPng,
  },
  {
    id: "9",
    href: "",
    name: "hotelReservation",
    taxonomy: "category",
    count: 0,
    thumbnail: "https://images.pexels.com/photos/3768095/pexels-photo-3768095.jpeg",
  },
  {
    id: "10",
    href: "/",
    name: "transfers",
    taxonomy: "category",
    count: 188288,
    thumbnail: transferPng,
  },
  {
    id: "11",
    href: "",
    name: "mice",
    taxonomy: "category",
    count: 188288,
    thumbnail: micePng,
  },
  {
    id: "12",
    href: "",
    name: "eventOrganization",
    taxonomy: "category",
    count: 188288,
    thumbnail: "https://images.unsplash.com/photo-1641598548876-33cd87372a50?q=80&w=2074&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
];

function PageHome() {
  const { t } = useTranslation(); // Translation hook

  // Function to translate all category names dynamically
  const translateCategories = (categories: TaxonomyType[]) =>
    categories.map(category => ({
      ...category,
      name: t(category.name),  // Translate the category name
    }));

  // Translated categories
  // const translatedCats = translateCategories(DEMO_CATS);
  const translatedYurticiCats = translateCategories(DEMO_CATS_YURTICI);
  const translatedYurtdisiCats = translateCategories(DEMO_CATS_YURTDISI);
  const translatedCats2 = translateCategories(DEMO_CATS_2);

  return (
    <div className="nc-PageHome relative overflow-hidden">
      <BgGlassmorphism />

      <div className="container relative space-y-24 mb-16 lg:space-y-12 lg:mb-16">
        <SectionHero3 className="pt-10 lg:pt-10 lg:pb-10" />


         <div className="flex flex-col gap-6"> {/* Increased space between sections */}
          <SectionSliderNewCategories
            heading={t("localTours") + " 🇹🇷 "}
            subHeading={t("exploreTurkey")}
            categoryCardType="card3"
            itemPerRow={5}
            categories={translatedYurticiCats}
            uniqueClassName="PageHome_s1"
          />
          <a href="/Turkiye_tours"  rel="noopener noreferrer" >  {/* Increased margin for button */}
            <ButtonPrimary>{i18n.t("seeAll")}</ButtonPrimary>
          </a>

          <SectionSliderNewCategories
            heading={t("popularInternationalTours") + " 🗺️ "}
            subHeading={t("exploreDestinations")}
            categoryCardType="card5"
            itemPerRow={3}
            categories={translatedYurtdisiCats}
            uniqueClassName="ListingFlightsPage-section1"
          />
          <a href="/International_tours"  rel="noopener noreferrer">
            <ButtonPrimary>{i18n.t("seeAll")}</ButtonPrimary>
          </a>
        </div>

      
        <SectionHero
          rightImg={holidayType}
          heading={" 🧭 " + t("holidayTypes")}
          btnText=""
          subHeading={t("holidayTypesParagraph")}
        />

        <div className="container relative space-y-24 mb-0">
          <div className="grid grid-cols-12 gap-7">
            <div className="col-span-12 sm:col-span-6 lg:col-span-4 grid grid-rows-2 gap-6">
              <CardCategory6 taxonomy={translatedCats2[0]} />
              <CardCategory6 taxonomy={translatedCats2[1]} />
              <CardCategory6 taxonomy={translatedCats2[2]} />
            </div>
            <div className="col-span-12 sm:col-span-6 lg:col-span-4 grid grid-rows-2 gap-6">
              <CardCategory6 taxonomy={translatedCats2[3]} />
              <CardCategory6 taxonomy={translatedCats2[4]} />
            </div>
            <div className="col-span-12 sm:col-span-6 lg:col-span-4 grid grid-rows-2 gap-6">
              <CardCategory6 taxonomy={translatedCats2[5]} />
              <CardCategory6 taxonomy={translatedCats2[6]} />
              <CardCategory6 taxonomy={translatedCats2[7]} />
            </div>
          </div>
        </div>

        <br />
        <SectionOurFeatures />


        {/* <SectionHowItWork /> */}
        <br />
        <SectionHero
          rightImg={""}
          heading={"🛎️" + t("services")}
        />

        <div className="container relative space-y-24 mb-0">
          <div className="grid grid-cols-9 gap-7">
            <div className="col-span-12 sm:col-span-6 lg:col-span-4 grid grid-rows-2 gap-6">
              <CardCategory6 taxonomy={translatedCats2[8]} />
              <CardCategory6 taxonomy={translatedCats2[9]} />
            </div>
            <div className="col-span-12 sm:col-span-6 lg:col-span-4 grid grid-rows-2 gap-6">
              <CardCategory6 taxonomy={translatedCats2[10]} />
              <CardCategory6 taxonomy={translatedCats2[11]} />
            </div>
          </div>
        </div>
        <br />
        <SectionStatistic />
        {/* <SectionSubscribe2 /> */}
      </div>
    </div>
  );
}

export default PageHome;
