import React, { FC } from "react";
import i18n from "i18next";
import { DEMO_EXPERIENCES_LISTINGS } from "data/listings";
import { ExperiencesDataType, StayDataType } from "data/types";
import Pagination from "shared/Pagination/Pagination";
import TabFilters from "./TabFilters";
import Heading2 from "components/Heading/Heading2";
import ExperiencesCard from "components/ExperiencesCard/ExperiencesCard";

// Interface for the props
export interface SectionGridFilterCardProps {
  className?: string;
  data?: StayDataType[];
}

const DEMO_DATA: ExperiencesDataType[] = DEMO_EXPERIENCES_LISTINGS.filter(
  (a, i) => i < 15
);

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
  data = DEMO_DATA,
}) => {
  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      <Heading2
        heading={i18n.t("localTours") + " 🇹🇷"}
        subHeading={
          <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
            {i18n.t("exploreTurkey")}
          </span>
        }
      />

      {/* <div className="mb-8 lg:mb-11">
        <TabFilters />
      </div> */}

      <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
        {data.map((stay) => {
          // Translate the title
          const translatedTitle = i18n.t(stay.title);

          return (
            <ExperiencesCard
              key={stay.id}
              data={stay}
              translatedTitle={translatedTitle}  // Pass the translated title to the card
            />
          );
        })}
      </div>

      {/* <div className="flex mt-16 justify-center items-center">
        <Pagination />
      </div> */}
    </div>
  );
};

export default SectionGridFilterCard;
