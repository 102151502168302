import React, { FC, useState } from "react";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import Textarea from "shared/Textarea/Textarea";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";
import DatePicker from "react-datepicker";
import SectionDateRange from "../../containers/ListingDetailPage/SectionDateRange";

export interface PageAddListing1Props {}

const PageAddListing1: FC<PageAddListing1Props> = () => {
  const [destination, setDestination] = useState<string>("");
  const [duration, setDuration] = useState<string>("");
  const [budget, setBudget] = useState<string>("");
  const [placeName, setPlaceName] = useState<string>("");
  const [rentalForm, setRentalForm] = useState<string>("");
  const [travelPlans, setTravelPlans] = useState<string>("");

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    // You can simulate the form submission, like logging the data to the console
    const formData = {
      destination,
      duration,
      budget,
      placeName,
      rentalForm,
      travelPlans
    };

    console.log("Form Submitted:", formData);
    
    // Optionally, you could simulate sending the data to an API here, or handle the form submission in other ways.
  };

  return (
    <CommonLayout index="01" backtHref="/add-listing-1" nextHref="/add-listing-2">
      <form onSubmit={handleSubmit}>
        <h2 className="text-2xl font-semibold">Choosing listing categories</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <h2 className="text-2xl font-semibold">Start planning your perfect Tailor-Made trip </h2>
        <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
          If you’re looking for your own immersive adventure that helps you explore local cultures in an authentic, sustainable, experience-rich way, you’ve come to the right place. We’re talking hanging out with Maasai warriors around a campfire, gliding through the jungle on a zipline in Costa Rica, or hunting for the best tapas in Spain with a local expert. For unforgettable adventures full of unique travel experiences and local interaction, the Intrepid Tailor-Made team is here to help.
        </span>

        <div className="space-y-8">
          {/* Destination Field */}
          <FormItem label="Where would you like to go?">
            <Input 
              placeholder="Choose as many destinations as you like"
              value={destination}
              onChange={(e) => setDestination(e.target.value)}
            />
          </FormItem>

          {/* Section Date Range */}
          <SectionDateRange />

          {/* Duration Field */}
          <FormItem label="For How Long?" desc="*4 Nights Min.">
            <Select 
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
            >
              <option value="">-Select-</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10+">10+</option>
            </Select>
          </FormItem>

          {/* Budget Field */}
          <FormItem label="Budget Per Person">
            <Select 
              value={budget}
              onChange={(e) => setBudget(e.target.value)}
            >
              <option value="">-Select-</option>
              <option value="500 USD - 1,000 USD">500 USD - 1,000 USD</option>
              <option value="1,000 USD - 2,000 USD">1,000 USD - 2,000 USD</option>
              <option value="2,000 USD - 3,000 USD">2,000 USD - 3,000 USD</option>
            </Select>
          </FormItem>

          {/* Place Name Field */}
          <FormItem label="Place name" desc="A catchy name usually includes: House name + Room name + Featured property + Tourist destination">
            <Input 
              placeholder="Places name"
              value={placeName}
              onChange={(e) => setPlaceName(e.target.value)}
            />
          </FormItem>

          {/* Rental Form Field */}
          <FormItem label="Rental form" desc="Entire place: Guests have the whole place to themselves—there's a private entrance and no shared spaces. A bedroom, bathroom, and kitchen are usually included.">
            <Select 
              value={rentalForm}
              onChange={(e) => setRentalForm(e.target.value)}
            >
              <option value="Entire place">Entire place</option>
              <option value="Private room">Private room</option>
              <option value="Shared room">Shared room</option>
            </Select>
          </FormItem>

          {/* Travel Plans Textarea */}
          <div>
            <h2 className="text-2xl font-semibold">Tell us about your travel plans</h2>
            <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
              Intrepid Tailor-Made trips include an element of touring, if you are looking for just a hotel and transfer or an all-inclusive resort package, then we recommend reaching out to a local travel agent. If you are looking to get to know the destination, meeting locals, and maybe try new food - we got you covered!
            </span>
          </div>
          <Textarea 
            placeholder="Tell us more..."
            rows={14}
            value={travelPlans}
            onChange={(e) => setTravelPlans(e.target.value)}
          />

          {/* Submit Button */}
          <div className="mt-8">
            <button type="submit" className="btn btn-primary">Submit</button>
          </div>
        </div>
      </form>
    </CommonLayout>
  );
};

export default PageAddListing1;
