import { DEMO_POSTS } from "data/posts";
import { PostDataType } from "data/types";
import React from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Comment from "shared/Comment/Comment";
import NcImage from "shared/NcImage/NcImage";
import SocialsList from "shared/SocialsList/SocialsList";
import Textarea from "shared/Textarea/Textarea";
import { Helmet } from "react-helmet";
import Header3 from "components/Header/Header3";
import Heading from "components/Heading/Heading";
import i18n from "i18next";

const BlogSingle = () => {

  const renderContent = () => {
    return (


      <div
        id="single-entry-content"
        className="prose dark:prose-invert prose-sm !max-w-[55rem] sm:prose lg:prose-lg mx-auto dark:prose-dark" style={{ fontSize: '1rem' }}
      >
        <p><strong>
          Kişisel Verilerin Korunması ve İşlenmesi Hakkında Aydınlatma Metni</strong>
        </p>

        <p>İşbu “Gizlilik Sözleşmesi, www.esmeraldatur.com sitesini ziyaret eden tüm kullanıcılar için geçerlilik kazanır. Bu web sitesini ziyaret etmekle ve bu site vasıtasıyla sunduğumuz hizmetlerden yararlanmayı talep etmekle işbu 'Gizlilik Sözleşmesi'nde belirtilen şartları kabul etmektesiniz.</p>

<p>esmeraldatur.com’u kişisel bilgilerinizi bırakmadan ziyaret edebilirsiniz. esmeraldatur.com’a üye olarak, talep yoluyla ya da bültene için kayıt yaptırmak suretiyle kayıt bırakmanız durumunda anonim kullanıcı olmaktan çıkarsınız. Bu noktadan itibaren bilgilerinizin Esmeralda Uluslararası Turizm Ticaret Danışmanlık ve Taahhüt Ltd. Şti.’ye aktarılmasına ve saklanmasına onay vermiş olursunuz. Esmeralda Uluslararası Turizm Ticaret Danışmanlık ve Taahhüt Ltd. Şti. 'nin kişisel bilgilerinizi aşağıda sayılan amaçlarla kullanabileceğini kabul etmiş sayılırsınız.</p>

<ul>
    <li>Talep ettiğiniz kullanıcı desteğini ve hizmetleri sağlamak;</li>
    <li>Hizmetleri, içerikleri ve tanıtımlarımızı kişiselleştirmek, ölçmek ve geliştirmek;</li>
    <li>Yasaklanma ihtimali olan veya yasaklanmış işlemleri veya kanuna aykırı faaliyetleri önlemek, bulmak ve araştırmak;</li>
    <li>Esmeralda Uluslararası Turizm Ticaret Danışmanlık ve Taahhüt Ltd. Şti.’ne ilişkin hizmetleri, hedeflenen pazarlama, hizmet güncelleştirilmeleri ve promosyon teklifleri ile ilgili tercih ettiğiniz iletişim kanalları çerçevesinde sizlere bilgiler vermek;</li>
    <li>Elektronik posta ile pazarlama amaçlı tanıtımlar göndermek; (Tanıtımlarının gönderilmemesi ile ilgili talimatlar gönderilen elektronik posta da yer almaktadır)</li>
    <li>Uyuşmazlıkları ve sitedeki aksaklıkları çözümlemek; İnternet sayfası akışımızı analiz etmemize, hizmetlerimizi kişiselleştirme, içerik ve reklamlarımızın tanıtım etkinliğini ölçmemize ve güvenliğimizi geliştirmemize yardımcı olması amacıyla sabit sürücünüze yerleşen küçük dosyalar olan çerezleri kullanmaktayız. Kullanıcılar dilerlerse çerezleri kabul etmek istemeyecek şekilde tarayıcı ayarlarını değiştirebilirler.</li>
</ul>

<p>esmeraldatur.com sitesiyle paylaştığınız kişisel bilgiler yetkisiz erişimlere ve açıklamalara karşı korunması için birçok araç (kriptolama, şifreler, fiziksel güvenlik vb.) kullanmaktayız. Kredi Kartı bilgileri kesinlikle saklanmaz, Kredi Kartı bilgileri sadece tahsilat işlemi sırasında ilgili bankalara/finans kuruluşlarına güvenli bir şekilde iletilerek provizyon alınması için kullanılır ve provizyon sonrası sistemden silinir.</p>

<p>esmeraldatur.com’a aşağıda yer alan iletişim bilgilerinden ulaşabilirsiniz.</p>

<ul>
    <li>Telefon: 0 312 231 88 55</li>
    <li>E-Mail: info@esmeraldatur.com</li>
</ul>
      </div>

    );
  };



  return (
    <div className="nc-PageSingle pt-8 lg:pt-16 ">
      <Helmet>
        <title>Esmeralda </title>
      </Helmet>
      <Heading desc="" isCenter>
        Çerezler & Gizlilik Sözleşmesi
      </Heading>

      {/* {renderHeader()} */}
      {/* <NcImage
        className="w-full max-w-xl rounded-xl"
        containerClassName="container my-10 sm:my-12 flex justify-center"
        src="https://images.unsplash.com/photo-1511578314322-379afb476865?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
      /> */}

      <div className="nc-SingleContent container space-y-10">
        {renderContent()} <br />

      </div>

    </div>
  );
};

export default BlogSingle;
