import { ListingGalleryImage } from "components/ListingImageGallery/utils/types";
import antalyaJpg from "images/antalya1.jpg"
import kasJpg from "images/kas1.jpg"
import mostarJpg from "images/mostar1.jpg"


export const PHOTOS: string[] = [
  antalyaJpg,
  kasJpg,
  "https://images.pexels.com/photos/6527036/pexels-photo-6527036.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/6969831/pexels-photo-6969831.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/6438752/pexels-photo-6438752.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/1320686/pexels-photo-1320686.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/261394/pexels-photo-261394.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/2861361/pexels-photo-2861361.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/2677398/pexels-photo-2677398.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/1365425/pexels-photo-1365425.jpeg?auto=compress&cs=tinysrgb&w=1600",
  "https://images.pexels.com/photos/914128/pexels-photo-914128.jpeg?auto=compress&cs=tinysrgb&w=1600",
  "https://images.pexels.com/photos/840667/pexels-photo-840667.jpeg?auto=compress&cs=tinysrgb&w=1600",
  "https://images.pexels.com/photos/732632/pexels-photo-732632.jpeg?auto=compress&cs=tinysrgb&w=1600",
  "https://images.pexels.com/photos/450062/pexels-photo-450062.jpeg?auto=compress&cs=tinysrgb&w=1600",
  "https://images.pexels.com/photos/917510/pexels-photo-917510.jpeg?auto=compress&cs=tinysrgb&w=1600",
  "https://images.pexels.com/photos/1194233/pexels-photo-1194233.jpeg?auto=compress&cs=tinysrgb&w=1600",
  "https://images.pexels.com/photos/236973/pexels-photo-236973.jpeg?auto=compress&cs=tinysrgb&w=1600",
  "https://images.pexels.com/photos/1392099/pexels-photo-1392099.jpeg?auto=compress&cs=tinysrgb&w=1600",
  "https://images.pexels.com/photos/547116/pexels-photo-547116.jpeg?auto=compress&cs=tinysrgb&w=1600",
  "https://images.pexels.com/photos/1002272/pexels-photo-1002272.jpeg?auto=compress&cs=tinysrgb&w=1600",
  "https://images.pexels.com/photos/917511/pexels-photo-917511.jpeg?auto=compress&cs=tinysrgb&w=1600",
  "https://images.pexels.com/photos/771079/pexels-photo-771079.jpeg?auto=compress&cs=tinysrgb&w=1600",
  "https://images.pexels.com/photos/13461077/pexels-photo-13461077.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load",
  "https://images.pexels.com/photos/9074921/pexels-photo-9074921.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load",
  "https://images.pexels.com/photos/9336042/pexels-photo-9336042.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load",
  "https://images.pexels.com/photos/5418318/pexels-photo-5418318.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load",
  "https://images.pexels.com/photos/4815278/pexels-photo-4815278.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load",
  "https://images.pexels.com/photos/1365425/pexels-photo-1365425.jpeg?auto=compress&cs=tinysrgb&w=1600",
];

export const PHOTOS_1: string[] = [
  antalyaJpg,
  "https://images.pexels.com/photos/6397695/pexels-photo-6397695.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  "https://images.pexels.com/photos/16772383/pexels-photo-16772383/free-photo-of-manavgat-waterfall-in-turkey.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  "https://images.unsplash.com/photo-1730196572072-d04ff6a345b2?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8cGhhc2VsaXN8ZW58MHx8MHx8fDA%3D",
  "https://images.unsplash.com/photo-1636619305015-1abb04b34e57?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
];

export const PHOTOS_2: string[] = [
  antalyaJpg,
  "https://images.pexels.com/photos/261394/pexels-photo-261394.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/2861361/pexels-photo-2861361.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/2677398/pexels-photo-2677398.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/1365425/pexels-photo-1365425.jpeg?auto=compress&cs=tinysrgb&w=1600",
  "https://images.pexels.com/photos/914128/pexels-photo-914128.jpeg?auto=compress&cs=tinysrgb&w=1600",
  "https://images.pexels.com/photos/840667/pexels-photo-840667.jpeg?auto=compress&cs=tinysrgb&w=1600",
  "https://images.pexels.com/photos/732632/pexels-photo-732632.jpeg?auto=compress&cs=tinysrgb&w=1600",
  "https://images.pexels.com/photos/450062/pexels-photo-450062.jpeg?auto=compress&cs=tinysrgb&w=1600",
  "https://images.pexels.com/photos/917510/pexels-photo-917510.jpeg?auto=compress&cs=tinysrgb&w=1600",
  "https://images.pexels.com/photos/1194233/pexels-photo-1194233.jpeg?auto=compress&cs=tinysrgb&w=1600",
  "https://images.pexels.com/photos/236973/pexels-photo-236973.jpeg?auto=compress&cs=tinysrgb&w=1600",
  "https://images.pexels.com/photos/1392099/pexels-photo-1392099.jpeg?auto=compress&cs=tinysrgb&w=1600",
  "https://images.pexels.com/photos/547116/pexels-photo-547116.jpeg?auto=compress&cs=tinysrgb&w=1600",
  "https://images.pexels.com/photos/1002272/pexels-photo-1002272.jpeg?auto=compress&cs=tinysrgb&w=1600",
  "https://images.pexels.com/photos/917511/pexels-photo-917511.jpeg?auto=compress&cs=tinysrgb&w=1600",
  "https://images.pexels.com/photos/771079/pexels-photo-771079.jpeg?auto=compress&cs=tinysrgb&w=1600",
  "https://images.pexels.com/photos/13461077/pexels-photo-13461077.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load",
  "https://images.pexels.com/photos/9074921/pexels-photo-9074921.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load",
  "https://images.pexels.com/photos/9336042/pexels-photo-9336042.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load",
  "https://images.pexels.com/photos/5418318/pexels-photo-5418318.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load",
  "https://images.pexels.com/photos/4815278/pexels-photo-4815278.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load",
  "https://images.pexels.com/photos/1365425/pexels-photo-1365425.jpeg?auto=compress&cs=tinysrgb&w=1600",
];

export const PHOTOS_WESTERN_BLACK_SEA: string[] = [
  "https://images.unsplash.com/photo-1696149333862-6999ecb65d0e?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  "https://images.unsplash.com/photo-1591023626854-371730a148e4?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  "https://images.unsplash.com/photo-1717760667646-b724148c8c83?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  "https://images.unsplash.com/photo-1599576237347-acffe42bea84?q=80&w=2676&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  "https://images.unsplash.com/photo-1575706906276-8a6e77e249ba?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NDN8fHpvbmd1bGRha3xlbnwwfHwwfHx8MA%3D%3D",
];

export const PHOTOS_NORTHERN_AEGEAN: string[] = [
  "https://images.unsplash.com/photo-1645485858906-56fc0972f31f?q=80&w=2087&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  "https://images.unsplash.com/photo-1583059692867-c1a5c4d98f74?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NDl8fGdlbGlib2x1fGVufDB8fDB8fHww",
  "https://images.unsplash.com/photo-1605981898978-5da28bfe5d45?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8YmVyZ2FtYXxlbnwwfHwwfHx8MA%3D%3D",
  "https://images.unsplash.com/photo-1691598881349-48bfa1447db4?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTh8fGF5dmFsJUM0JUIxa3xlbnwwfHwwfHx8MA%3D%3D",
  "https://images.unsplash.com/photo-1606202741483-1e7467d504f9?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8JUMzJUE3YW5ha2thbGV8ZW58MHx8MHx8fDA%3D",
];

export const PHOTOS_EASTERN_BLACK_SEA: string[] = [
  "https://images.unsplash.com/photo-1658680840938-ba65ed2d5ad6?q=80&w=2664&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  "https://images.unsplash.com/photo-1568960713091-53ccd34c0d7f?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8cml6ZXxlbnwwfHwwfHx8MA%3D%3D",
  "https://images.unsplash.com/photo-1509879002078-cb8024be13d3?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8YW1hc3lhfGVufDB8fDB8fHww",
  "https://images.unsplash.com/photo-1625566360146-918001e76064?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDl8fHxlbnwwfHx8fHw%3D",
  "https://images.unsplash.com/photo-1580979514255-acfc218d1c9f?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTh8fGJhdCVDNCVCMSUyMGthcmFkZW5penxlbnwwfHwwfHx8MA%3D%3D",
];

export const PHOTOS_ISTANBUL: string[] = [
  "https://images.unsplash.com/photo-1602941800793-78c44baca4d8?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  "https://images.unsplash.com/photo-1621165398565-6df8162f578b?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  "https://images.unsplash.com/photo-1524231757912-21f4fe3a7200?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  "https://images.unsplash.com/photo-1601546992562-80dde63e3df2?q=80&w=2012&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  "https://images.unsplash.com/photo-1724889835445-f28b7f01b989?q=80&w=2128&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
];

export const PHOTOS_CAPPADOCIA: string[] = [

  "https://images.unsplash.com/photo-1669111957493-5decbb7418d8?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NDZ8fGNhcHBhZG9jaWF8ZW58MHx8MHx8fDA%3D",
  "https://images.unsplash.com/photo-1715029573636-3ea6c8ce56f8?q=80&w=1959&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  "https://images.unsplash.com/photo-1669046638062-521ed8d648c8?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8JUM0JUIxaGxhcmF8ZW58MHx8MHx8fDA%3D",
  "https://images.unsplash.com/photo-1691156526294-042eb43a6112?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NzJ8fGNhcHBhZG9jaWF8ZW58MHx8MHx8fDA%3D",
  "https://images.unsplash.com/photo-1691156550450-e2099ed3ac55?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
];

export const PHOTOS_ANTALYA2: string[] = [

  "https://images.unsplash.com/photo-1674715577456-49ae7a6945a8?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D0",
  "https://images.pexels.com/photos/21794148/pexels-photo-21794148/free-photo-of-beach-in-city-in-bay.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  antalyaJpg,
  "https://images.unsplash.com/photo-1613335869821-656342543921?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjB8fGNpcmFsaXxlbnwwfHwwfHx8MA%3D%3D",
  "https://images.pexels.com/photos/8199460/pexels-photo-8199460.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
];

export const PHOTOS_EASTERN_ANATOLIA: string[] = [

  "https://images.pexels.com/photos/17906999/pexels-photo-17906999/free-photo-of-trees-and-chapel-on-hill-over-lake.jpeg",
  "https://images.unsplash.com/photo-1665933758639-5bb088aee91c?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  "https://images.unsplash.com/photo-1639610624679-0676169821cb?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  "https://images.pexels.com/photos/13872010/pexels-photo-13872010.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  "https://images.pexels.com/photos/27524140/pexels-photo-27524140/free-photo-of-the-entrance-to-a-building-with-intricate-carvings.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
];

export const PHOTOS_OTTOMAN_CAPITALS: string[] = [

  "https://images.unsplash.com/photo-1663212946658-ed3e96eef884?q=80&w=2072&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  "https://images.unsplash.com/photo-1687986480933-46914e5fc62c?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  "https://images.pexels.com/photos/15020109/pexels-photo-15020109/free-photo-of-doorway-in-ottoman-mosque.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  "https://images.pexels.com/photos/29477031/pexels-photo-29477031/free-photo-of-intricate-ottoman-interior-with-detailed-cini-tiles.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  "https://images.unsplash.com/photo-1649174988469-32e0bccaa668?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  "https://images.pexels.com/photos/9486104/pexels-photo-9486104.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
];

export const PHOTOS_WESTERN_AND_CENTRAL_BLACK_SEA: string[] = [

  "https://images.unsplash.com/photo-1526788782399-9bb3760eb1b7?q=80&w=2072&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  "https://images.unsplash.com/photo-1599576237347-acffe42bea84?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8c2lub3B8ZW58MHx8MHx8fDA%3D",
  "https://images.unsplash.com/photo-1678719887881-a897f3823e29?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  "https://images.unsplash.com/photo-1509879002078-cb8024be13d3?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8YW1hc3lhfGVufDB8fDB8fHww",
  "https://images.unsplash.com/photo-1688001898689-1db0e4df3ed2?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MzN8fCVDNSU5RmlsZXxlbnwwfHwwfHx8MA%3D%3D"
];

export const PHOTOS_TILLO: string[] = [

  "https://images.pexels.com/photos/18285246/pexels-photo-18285246/free-photo-of-scenic-panorama-of-a-river-in-a-mountain-canyon.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  "https://siirt.ktb.gov.tr/Resim/464668,whatsapp-image-2024-11-11-at-144150jpeg.png?0"
];

export const PHOTOS_RUSSIA: string[] = [

  "https://images.unsplash.com/photo-1513326738677-b964603b136d?q=80&w=1949&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  "https://images.unsplash.com/photo-1561117937-45681fb935ad?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTV8fG1vc2Nvd3xlbnwwfHwwfHx8MA%3D%3D",
  "https://images.unsplash.com/photo-1558682325-7cf48d328e34?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8c3QuJTIwcGV0ZXJzYnVyZ3xlbnwwfHwwfHx8MA%3D%3D",
  "https://images.unsplash.com/photo-1589983809059-553a00f72572?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  "https://images.unsplash.com/photo-1615529489302-e5e8d9f72ce8?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8aGVybWl0YWdlfGVufDB8fDB8fHww",
];

export const PHOTOS_GREECE: string[] = [

  "https://images.unsplash.com/photo-1645359271414-3697b7a5781f?q=80&w=2114&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  "https://images.unsplash.com/photo-1599423217192-34da246be9e8?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  "https://images.unsplash.com/photo-1645099957720-987a8b6d1394?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  "https://images.unsplash.com/photo-1649965163799-5883ce0df955?q=80&w=1982&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  "https://images.unsplash.com/photo-1707380398688-8ce9d350847f?q=80&w=2126&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  "",
];

export const PHOTOS_MOROCCO: string[] = [

    "https://images.unsplash.com/photo-1526928878027-1cf28c1405ba?q=80&w=2135&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1535191059345-c16453b851b2?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1710815759632-2d922bb933e7?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1729456229097-e60798212180?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1579283135011-0974a412341a?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
  ];

  export const PHOTOS_BALKANS: string[] = [

    mostarJpg,
    "https://images.unsplash.com/photo-1682936770708-3a8d9301a151?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjN8fGJ1ZHZhfGVufDB8fDB8fHww",
    "https://images.unsplash.com/photo-1622151680932-c855a0a0b011?q=80&w=2023&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1653389167152-7dbd6d165631?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1607625901435-5f896ba02bf5?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8c3ZldGklMjBzdGVmYW58ZW58MHx8MHx8fDA%3D"
  ];

  export const PHOTOS_CUBA: string[] = [

    "https://images.unsplash.com/photo-1500759285222-a95626b934cb?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1689630132300-4df203e0cac4?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NzJ8fHRyaW5pZGFkfGVufDB8fDB8fHww",
    "https://images.unsplash.com/flagged/photo-1620866234358-e90ea9db3067?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1705782456474-1ff5a5ca2b9e?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1691095574546-21a767f852b5?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
  ];

  export const PHOTOS_SCANDINAVIA: string[] = [

    "https://images.unsplash.com/photo-1513622470522-26c3c8a854bc?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1724498603913-f734c9735805?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDd8fHxlbnwwfHx8fHw%3D",
    "https://images.unsplash.com/photo-1518517611416-da57df49b8d3?q=80&w=2092&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://plus.unsplash.com/premium_photo-1697730017462-b99adb09466f?q=80&w=2072&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1508189860359-777d945909ef?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
    
  ];

export const Amenities_demos = [
  { name: "la-key", icon: "la-key" },
  { name: "la-luggage-cart", icon: "la-luggage-cart" },
  { name: "la-shower", icon: "la-shower" },
  { name: "la-smoking", icon: "la-smoking" },
  { name: "la-snowflake", icon: "la-snowflake" },
  { name: "la-spa", icon: "la-spa" },
  { name: "la-suitcase", icon: "la-suitcase" },
  { name: "la-suitcase-rolling", icon: "la-suitcase-rolling" },
  { name: "la-swimmer", icon: "la-swimmer" },
  { name: "la-swimming-pool", icon: "la-swimming-pool" },
  { name: "la-tv", icon: "la-tv" },
  { name: "la-umbrella-beach", icon: "la-umbrella-beach" },
  { name: "la-utensils", icon: "la-utensils" },
  { name: "la-wheelchair", icon: "la-wheelchair" },
  { name: "la-wifi", icon: "la-wifi" },
  { name: "la-baby-carriage", icon: "la-baby-carriage" },
  { name: "la-bath", icon: "la-bath" },
  { name: "la-bed", icon: "la-bed" },
  { name: "la-briefcase", icon: "la-briefcase" },
  { name: "la-car", icon: "la-car" },
  { name: "la-cocktail", icon: "la-cocktail" },
  { name: "la-coffee", icon: "la-coffee" },
  { name: "la-concierge-bell", icon: "la-concierge-bell" },
  { name: "la-dice", icon: "la-dice" },
  { name: "la-dumbbell", icon: "la-dumbbell" },
  { name: "la-hot-tub", icon: "la-hot-tub" },
  { name: "la-infinity", icon: "la-infinity" },
];

export const imageGallery: ListingGalleryImage[] = [...PHOTOS_1].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const imageGallery_1: ListingGalleryImage[] = [...PHOTOS_1].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const includes_demo = [
  { name: "serviceIncluded1" },
  { name: "serviceIncluded2" },
  { name: "serviceIncluded3" },
  { name: "serviceIncluded4" },
  { name: "serviceIncluded5" },
  { name: "serviceIncluded6" },
  { name: "serviceIncluded7" },
  { name: "serviceIncluded8" },
  { name: "serviceIncluded9" }
];

export const antalya_tour_6_7 = [
  { name: "serviceIncluded1" },
  { name: "serviceIncluded2" },
  { name: "serviceIncluded3" },
  { name: "serviceIncluded4" },
  { name: "serviceIncluded5" },
  { name: "serviceIncluded6" },
  { name: "serviceIncluded7" },
  { name: "serviceIncluded8" },
  { name: "serviceIncluded9" }
];

export const antalya_tour2 = [
  { name: "serviceIncluded1" },
  { name: "serviceIncluded2_4" },
  { name: "serviceIncluded3_4" },
  { name: "serviceIncluded4" },
  { name: "serviceIncluded5" },
  { name: "serviceIncluded6" },
  { name: "serviceIncluded7" },
  { name: "serviceIncluded8" },
  { name: "serviceIncluded9" },
  { name: "serviceIncluded17" },
];

export const Istanbul_tour_includes = [
  { name: "serviceIncluded1" },
  { name: "serviceIncluded2_5" },
  { name: "serviceIncluded3_5" },
  { name: "serviceIncluded4" },
  { name: "serviceIncluded5" },
  { name: "serviceIncluded6" },
  { name: "serviceIncluded7" },
  { name: "serviceIncluded16" },
  { name: "serviceIncluded8" },
  { name: "serviceIncluded9" }
];

export const Cappadocia_tour_includes = [
  { name: "serviceIncluded1" },
  { name: "serviceIncluded2_6" },
  { name: "serviceIncluded3_6" },
  { name: "serviceIncluded4" },
  { name: "serviceIncluded5" },
  { name: "serviceIncluded6" },
  { name: "serviceIncluded7" },
  { name: "serviceIncluded8" },
  { name: "serviceIncluded9" }
];

export const eastern_black_sea_tour_6_7 = [
  { name: "serviceIncluded1" },
  { name: "serviceIncluded2" },
  { name: "serviceIncluded3" },
  { name: "serviceIncluded4" },
  { name: "serviceIncluded5" },
  { name: "serviceIncluded6" },
  { name: "serviceIncluded7" },
  { name: "serviceIncluded8" },
  { name: "serviceIncluded9" },
  { name: "serviceIncluded12" },
  { name: "serviceIncluded13" }
];

export const northern_aegean_tour_6_7 = [
  { name: "serviceIncluded1" },
  { name: "serviceIncluded2" },
  { name: "serviceIncluded3" },
  { name: "serviceIncluded4" },
  { name: "serviceIncluded5" },
  { name: "serviceIncluded6" },
  { name: "serviceIncluded7" },
  { name: "serviceIncluded8" },
  { name: "serviceIncluded9" },
  { name: "serviceIncluded14" },
  { name: "serviceIncluded15" }
];

export const western_black_sea_tour_5_6 = [
  { name: "serviceIncluded1" },
  { name: "serviceIncluded2_2" },
  { name: "serviceIncluded3_2" },
  { name: "serviceIncluded4" },
  { name: "serviceIncluded5" },
  { name: "serviceIncluded6" },
  { name: "serviceIncluded7" },
  { name: "serviceIncluded8" },
  { name: "serviceIncluded9" },
  { name: "serviceIncluded10" },
  { name: "serviceIncluded11" }
];

export const eastern_anatolia_tour = [
  { name: "serviceIncluded1" },
  { name: "serviceIncluded2" },
  { name: "serviceIncluded3" },
  { name: "serviceIncluded4" },
  { name: "serviceIncluded5" },
  { name: "serviceIncluded6" },
  { name: "serviceIncluded7" },
  { name: "serviceIncluded8" },
  { name: "serviceIncluded9" },
  { name: "serviceIncluded18" },
];

export const Ottoman_capitals_tour_includes = [
  { name: "serviceIncluded1" },
  { name: "serviceIncluded2" },
  { name: "serviceIncluded3" },
  { name: "serviceIncluded4" },
  { name: "serviceIncluded5" },
  { name: "serviceIncluded6" },
  { name: "serviceIncluded7" },
  { name: "serviceIncluded8" },
  { name: "serviceIncluded9" },
  { name: "serviceIncluded19" },
];

export const Tillo_tour_includes = [
  { name: "serviceIncluded1" },
  { name: "serviceIncluded2_6" },
  { name: "serviceIncluded3_6" },
  { name: "serviceIncluded4" },
  { name: "serviceIncluded5" },
  { name: "serviceIncluded6" },
  { name: "serviceIncluded7" },
  { name: "serviceIncluded8" },
  { name: "serviceIncluded9" }
];

export const Western_and_central_black_sea_tour_includes = [
  { name: "serviceIncluded1" },
  { name: "serviceIncluded2_6" },
  { name: "serviceIncluded3_6" },
  { name: "serviceIncluded4" },
  { name: "serviceIncluded5" },
  { name: "serviceIncluded6" },
  { name: "serviceIncluded7" },
  { name: "serviceIncluded8" },
  { name: "serviceIncluded9" }
];

export const moscow_st_petersburg_includes = [
  { name: "serviceIncluded1" },
  { name: "serviceIncluded2" },
  { name: "serviceIncluded3" },
  { name: "serviceIncluded4" },
  { name: "serviceIncluded5" },
  { name: "serviceIncluded6" },
  { name: "serviceIncluded7" },
  { name: "serviceIncluded9" }
];

export const greece_includes = [
  { name: "serviceIncluded1" },
  { name: "serviceIncluded2" },
  { name: "serviceIncluded3" },
  { name: "serviceIncluded4" },
  { name: "serviceIncluded5" },
  { name: "serviceIncluded6" },
  { name: "serviceIncluded7" },
  { name: "serviceIncluded9" }
];

export const morocco_includes = [
  { name: "serviceIncluded1" },
  { name: "serviceIncluded2" },
  { name: "serviceIncluded3" },
  { name: "serviceIncluded4" },
  { name: "serviceIncluded5" },
  { name: "serviceIncluded6" },
  { name: "serviceIncluded7" },
  { name: "serviceIncluded9" }
];

export const balkans_includes = [
  { name: "serviceIncluded1" },
  { name: "serviceIncluded2" },
  { name: "serviceIncluded3" },
  { name: "serviceIncluded4" },
  { name: "serviceIncluded5" },
  { name: "serviceIncluded6" },
  { name: "serviceIncluded7" },
  { name: "serviceIncluded9" }
];

export const moscow_st_petersburg_excludes = [
  { name: "serviceExcluded1" },
  { name: "serviceExcluded2" },
  { name: "serviceExcluded3" },
  { name: "serviceExcluded4" },
  { name: "serviceExcluded6" }
];

export const greece_excludes = [
  { name: "serviceExcluded1" },
  { name: "serviceExcluded2" },
  { name: "serviceExcluded3" },
  { name: "serviceExcluded4" },
  { name: "serviceExcluded6" }
];

export const morocco_excludes = [
  { name: "serviceExcluded1" },
  { name: "serviceExcluded2" },
  { name: "serviceExcluded3" },
  { name: "serviceExcluded4" },
  { name: "serviceExcluded6" }
];

export const balkans_excludes = [
  { name: "serviceExcluded1" },
  { name: "serviceExcluded2" },
  { name: "serviceExcluded3" },
  { name: "serviceExcluded4" },
  { name: "serviceExcluded6" }
];

export const antalya_tour_6_7_excludes = [
  { name: "serviceExcluded1" },
  { name: "serviceExcluded2" },
  { name: "serviceExcluded3" },
  { name: "serviceExcluded4" },
  { name: "serviceExcluded5" },
  { name: "serviceExcluded6" },
];

export const antalya_tour2_excludes = [
  { name: "serviceExcluded1" },
  { name: "serviceExcluded2" },
  { name: "serviceExcluded3" },
  { name: "serviceExcluded4" },
  { name: "serviceExcluded5" },
  { name: "serviceExcluded6" },
  { name: "serviceExcluded8" },
];

export const Istanbul_tour_excludes = [
  { name: "serviceExcluded1" },
  { name: "serviceExcluded2" },
  { name: "serviceExcluded3" },
  { name: "serviceExcluded4" },
  { name: "serviceExcluded5" },
  { name: "serviceExcluded6" },
];

export const Cappadocia_tour_excludes = [
  { name: "serviceExcluded1" },
  { name: "serviceIncluded2" },
  { name: "serviceIncluded3" },
  { name: "serviceExcluded4" },
  { name: "serviceExcluded5" },
  { name: "serviceExcluded6" },
];

export const eastern_black_sea_tour_6_7_excludes = [
  { name: "serviceExcluded1" },
  { name: "serviceExcluded2" },
  { name: "serviceExcluded3" },
  { name: "serviceExcluded4" },
  { name: "serviceExcluded5" },
  { name: "serviceExcluded6" },
];

export const northern_aegean_tour_6_7_excludes = [
  { name: "serviceExcluded1" },
  { name: "serviceExcluded2" },
  { name: "serviceExcluded3" },
  { name: "serviceExcluded4" },
  { name: "serviceExcluded5" },
  { name: "serviceExcluded6" },
];

export const western_black_sea_tour_5_6_excludes = [
  { name: "serviceExcluded1" },
  { name: "serviceExcluded2" },
  { name: "serviceExcluded3" },
  { name: "serviceExcluded4" },
  { name: "serviceExcluded5" },
  { name: "serviceExcluded6" },
];

export const eastern_anatolia_tour_excludes = [
  { name: "serviceExcluded1" },
  { name: "serviceExcluded2" },
  { name: "serviceExcluded3" },
  { name: "serviceExcluded4" },
  { name: "serviceExcluded5" },
  { name: "serviceExcluded6" }
];

export const Ottoman_capitals_tour_excludes = [
  { name: "serviceExcluded1" },
  { name: "serviceExcluded2" },
  { name: "serviceExcluded3" },
  { name: "serviceExcluded4" },
  { name: "serviceExcluded5" },
  { name: "serviceExcluded6" }
];

export const Western_and_central_black_sea_tour_excludes = [
  { name: "serviceExcluded1" },
  { name: "serviceExcluded2" },
  { name: "serviceExcluded3" },
  { name: "serviceExcluded4" },
  { name: "serviceExcluded5" },
  { name: "serviceExcluded6" }
];

export const Tillo_tour_excludes = [
  { name: "serviceExcluded1" },
  { name: "serviceExcluded2" },
  { name: "serviceExcluded3" },
  { name: "serviceExcluded4" },
  { name: "serviceExcluded5" },
  { name: "serviceExcluded6" }
];


export const imageGallery_2: ListingGalleryImage[] = [...PHOTOS_2].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);


