import React, { FC, ReactNode } from "react";
import i18n from "i18next";
import HeroSearchForm, { SearchTab } from "components/HeroSearchForm/HeroSearchForm";

export interface SectionHeroArchivePage2Props {  // Make sure the prop type is also renamed
  className?: string;
  listingType?: ReactNode;
  currentPage: "Stays" | "Experiences" | "Cars" | "Flights";
  currentTab: SearchTab;
  rightImage?: string;
}

const SectionHeroArchivePage2: FC<SectionHeroArchivePage2Props> = ({  // Rename the component here
  className = "",
  listingType,
  currentPage,
  currentTab,
  rightImage = "https://images.unsplash.com/photo-1521295121783-8a321d551ad2?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
}) => {
  return (
    <div
      className={`nc-SectionHeroArchivePage2 flex flex-col relative ${className}`}
      data-nc-id="SectionHeroArchivePage2"
    >
      <div className="flex flex-col lg:flex-row lg:items-center">
        <div className="flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-6 lg:space-y-10 pb-14 lg:pb-64 xl:pb-80 xl:pr-14 lg:mr-10 xl:mr-0">
          <h2 className="font-semibold text-4xl md:text-4xl xl:text-5xl leading-[110%]">
            {i18n.t("internationalTours") + " 🗺️"}
          </h2>
          <div className="flex items-center text-base md:text-medium text-neutral-500 dark:text-neutral-400 tracking-wide leading-relaxed">
            <span className="ml-2.5">
              <i className="text-2xl las la-compass"></i> {i18n.t("internationalToursParagraph")}
            </span>
          </div>
        </div>

        {/* The image will be hidden on mobile, shown on larger screens */}
        <div className="flex-grow hidden lg:block">
        <div className="flex-grow hidden lg:block">
  <img 
    className="w-full rounded-lg" 
    src={rightImage} 
    alt="hero" 
    style={{ borderRadius: '15px', width: '100%' }} // 90% of the original width
  />
</div>

        </div>
      </div>

      <div className="hidden lg:flow-root w-full">
        <div className="z-10 lg:-mt-40 xl:-mt-56 w-full">
          {/* <HeroSearchForm currentPage={currentPage} currentTab={currentTab} /> */}
        </div>
      </div>
    </div>
  );
};

export default SectionHeroArchivePage2;  // Don't forget to export the correct name
