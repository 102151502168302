import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import resources from "language.json";

// Function to map browser language to i18n language keys
const getBrowserLanguage = () => {
  const browserLang = navigator.language || navigator.languages[0];
  // Map browser language to your specific i18n language keys
  switch (browserLang) {
    case 'en-US':
    case 'en':
      return 'en_EN';
    case 'tr':
    case 'tr-TR':
      return 'tr_TR';
    case 'ru':
    case 'ru-RU':
      return 'ru_RU';
    case 'de':
    case 'de-DE':
      return 'de_DE';
    case 'es':
    case 'es-ES':
      return 'es_ES';
    case 'fr':
    case 'fr-FR':
      return 'fr_FR';
    default:
      return 'en_EN'; // Default to English if no match
  }
};

// Get the language from localStorage (if set), otherwise fall back to the browser's language
const getSavedLanguage = () => {
  const savedLang = localStorage.getItem('language');
  return savedLang ? savedLang : getBrowserLanguage(); // If no language is saved, use browser language
};

// Set up i18n with the saved or browser language
i18n
  .use(initReactI18next) 
  .init({
    resources,
    lng: getSavedLanguage(), // Use the language from localStorage or fallback to browser language
    fallbackLng: "en_EN", // Fallback language in case the saved or browser language is not available
    interpolation: {
      escapeValue: false // React already escapes by default
    }
  });

// Listen for language change events and update localStorage
i18n.on('languageChanged', (lng) => {
  localStorage.setItem('language', lng); // Save the new language to localStorage
});

export default i18n;
