import React from "react";
import { Link } from "react-router-dom";
import logoImg from "images/logoesm.png";
import logoLightImg from "images/logoesm.png";
import LogoSvgLight from "./LogoSvgLight";
import LogoSvg from "./LogoSvg";

export interface LogoProps {
  img?: string;
  imgLight?: string;
  className?: string;
}

const Logo: React.FC<LogoProps> = ({
  img = logoImg,
  imgLight = logoLightImg,
  className = "w-24", // Default class to control width
}) => {
  return (
    <Link
      to="/"
      className={`ttnc-logo inline-block text-primary-6000 focus:outline-none focus:ring-0 ${className}`}
    >
      {/* Responsive size adjustments */}
      <img
        className={`block max-h-12 sm:max-h-16 md:max-h-20 lg:max-h-24 xl:max-h-28 ${img}`}
        src={img}
        alt="Logo"
      />
      {/* Optional light logo for dark mode */}
      {/* {imgLight && (
        <img
          className="hidden max-h-12 dark:block sm:max-h-16 md:max-h-20 lg:max-h-24 xl:max-h-28"
          src={imgLight}
          alt="Logo-Light"
        />
      )} */}
    </Link>
  );
};

export default Logo;
