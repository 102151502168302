import React, { FC } from "react";
import Logo from "shared/Logo/Logo";
import Navigation from "shared/Navigation/Navigation";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import MenuBar from "shared/MenuBar/MenuBar";

import LangDropdown from "./LangDropdown";

import i18n from "i18next";

export interface MainNav1Props {
  className?: string;
}

const MainNav1: FC<MainNav1Props> = ({ className = "" }) => {
  return (
    <div className={`nc-MainNav1 relative z-10 ${className}`}>
      <div className="px-4 lg:container py-4 lg:py-5 relative flex justify-between items-center">
        {/* Logo and Navigation for Small to Medium Screens (Mobile & Tablet) */}
        <div className="flex justify-between items-center w-full">
          {/* Logo on Small Screens (and on far left for Desktop) */}
          <div className="flex items-center mr-auto">
            <Logo />
          </div>

          {/* Navigation & Button for Small to Medium Screens */}
          <div className="hidden md:flex justify-start flex-1 items-center space-x-4 sm:space-x-10 ml-10"> {/* Added ml-10 to create more space between Logo and Navigation */}
            <Navigation />
          </div>

          {/* Contact Button & LangDropdown (Visible on all screen sizes) */}
          <div className="flex items-center space-x-3 md:space-x-5">
            <ButtonPrimary href="/contact">{i18n.t("contactUs")}</ButtonPrimary>
            <LangDropdown />
          </div>
        </div>

        {/* Show MenuBar only on Medium Screens */}
        <div className="hidden md:block lg:hidden">
          <MenuBar />
        </div>

        {/* Desktop Specific Content */}
        <div className="hidden md:flex flex-shrink-0 items-center justify-end flex-1 lg:flex-none text-neutral-700 dark:text-neutral-100">
          <div className="hidden xl:flex items-center space-x-0.5">
            {/* Additional Desktop Content (like SwitchDarkMode, SearchDropdown) */}
            <div className="px-1" />
          </div>
          <div className="flex xl:hidden items-center">
            {/* Mobile-specific or collapsed version */}
            <div className="px-0.5" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainNav1;
