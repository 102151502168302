import React from "react";
import MyRouter from "routers/index";
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n'; // Adjust the path if necessary
import { useState, useEffect } from "react";


function App() {
  const [language, setLanguage] = useState(i18n.language);

  useEffect(() => {
    const handleLanguageChange = () => {
      setLanguage(i18n.language); // Update state to force re-render
    };

    // Listen for language change event
    i18n.on('languageChanged', handleLanguageChange);

    // Cleanup on component unmount
    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, []);
  
  return (
    <I18nextProvider i18n={i18n}>
      <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
        <MyRouter />
      </div>
    </I18nextProvider>
  );
}

export default App;
