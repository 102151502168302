import React, { FC } from "react";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SocialsList from "shared/SocialsList/SocialsList";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import i18n from "i18next";

// Removed form-related state and functions

const info = [
  {
    title: "📍",
    desc: "İlkbahar Mah. 593 Sok. 4/4, Turan Güneş Çankaya/Ankara",
  },
  {
    title: "✉️",
    desc: "info@esmeraldatur.com",
  },
  {
    title: "☎",
    desc: "+90 (312) 990 05 55",
  },
];

export interface PageContactProps {
  className?: string;
}

const PageContact: FC<PageContactProps> = ({ className = "" }) => {
  return (
    <div className={`nc-PageContact overflow-hidden ${className}`} data-nc-id="PageContact">
      <Helmet>
        <title>Esmeralda</title>
      </Helmet>
      <div className="mb-24 lg:mb-32">
        <h2 className="my-16 sm:my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
        {i18n.t("contactUs")}
        </h2>
        <div className="container max-w-7xl mx-auto">
          <div className="flex-shrink-0 grid grid-cols-1 sm:grid-cols-2 gap-12">
            <div className="max-w-sm space-y-8">
              {info.map((item, index) => (
                <div key={index}>
                  <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                    {item.title}
                  </h3>
                  <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    {item.desc}
                  </span>
                </div>
              ))}
              {/* <div>
                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  🌏 SOCIALS
                </h3>
                <SocialsList className="mt-2" />
              </div> */}
            </div>
            <div>
              {/* Replacing the form with an image, hidden on mobile, with rounded edges */}
              <img 
                src="https://images.unsplash.com/photo-1499159058454-75067059248a?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTJ8fGNvbnRhY3R8ZW58MHx8MHx8fDA%3D" 
                alt="Your description here" 
                className="hidden lg:block w-full h-auto rounded-lg" 
              />
            </div>
          </div>
        </div>
      </div>

      {/* OTHER SECTIONS */}
      <div className="container">
        {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="Pagecontact_" />
        </div> */}
        {/* <SectionSubscribe2 className="py-24 lg:py-32" /> */}
      </div>
    </div>
  );
};

export default PageContact;
