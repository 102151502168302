import React, { FC } from "react";
import rightImgPng from "images/our-features.png";
import NcImage from "shared/NcImage/NcImage";
import Badge from "shared/Badge/Badge";
import { useTranslation } from 'react-i18next';

export interface SectionOurFeaturesProps {
  className?: string;
  rightImg?: string;
  type?: "type1" | "type2";
}

const SectionOurFeatures: FC<SectionOurFeaturesProps> = ({
  className = "lg:py-14",
  rightImg = rightImgPng,
  type = "type1",
}) => {
  const { t } = useTranslation(); // Initialize the translation hook
  return (
    <div
      className={`nc-SectionOurFeatures relative flex flex-col items-center ${type === "type1" ? "lg:flex-row" : "lg:flex-row-reverse"
        } ${className}`}
      data-nc-id="SectionOurFeatures"
    >
      <div className="flex-grow">
        <NcImage src={rightImg} />
      </div>
      <div
        className={`max-w-2xl flex-shrink-0 mt-10 lg:mt-0 lg:w-2/5 ${type === "type1" ? "lg:pl-16" : "lg:pr-16"
          }`}
      >
        <span className="uppercase text-sm text-gray-400 tracking-widest">

        </span>
        {/* <h2 className="font-semibold text-4xl mt-5">Happening cities </h2> */}

        <ul className="space-y-10 mt-16">
          <li className="space-y-4">
            <Badge color="green" name={t('badgeTitle1')} />
            {/* <span className="block text-xl font-semibold">
              Tailored Travel Experiences
            </span> */}
            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
            {t('badgeParagraph1')}
            </span>
          </li>
          <li className="space-y-4">
            <Badge color="blue" name={t('badgeTitle2')} />
            {/* <span className="block text-xl font-semibold">
              Explore the World
            </span> */}
            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
            {t('badgeParagraph2')}
            </span>
          </li>
          <li className="space-y-4">
            <Badge color="green" name={t('badgeTitle3')} />
            {/* <span className="block text-xl font-semibold">
              Travel Expertise You Can Trust
            </span> */}
            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
            {t('badgeParagraph3')}
            </span>
          </li>
          <li className="space-y-4">
            <Badge color="blue" name={t('badgeTitle4')} />
            {/* <span className="block text-xl font-semibold">
              Exclusive & Authentic Experiences
            </span> */}
            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
            {t('badgeParagraph4')}
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SectionOurFeatures;
