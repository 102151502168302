import { DEMO_POSTS } from "data/posts";
import { PostDataType } from "data/types";
import React from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Comment from "shared/Comment/Comment";
import NcImage from "shared/NcImage/NcImage";
import SocialsList from "shared/SocialsList/SocialsList";
import Textarea from "shared/Textarea/Textarea";
import { Helmet } from "react-helmet";
import Header3 from "components/Header/Header3";
import Heading from "components/Heading/Heading";
import i18n from "i18next";

const BlogSingle = () => {

  const renderContent = () => {
    return (
      // <div
      //   id="single-entry-content"
      //   className="prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
      // >

      <div
        id="single-entry-content"
        className="prose dark:prose-invert prose-sm !max-w-[55rem] sm:prose lg:prose-lg mx-auto dark:prose-dark"
      >

        <figure>
          <img
            src="https://images.unsplash.com/photo-1451187580459-43490279c0fa?q=80&w=2072&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt="nc blog"
            className="rounded-2xl"
          />

        </figure>
        <p>
        {i18n.t("miceParagraph1")}
        </p>
        
        <figure>
          <img
            src="https://images.unsplash.com/photo-1698573708987-02d1034e8243?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt="nc blog"
            className="rounded-2xl"
          />

        </figure>
        <p>{i18n.t("miceParagraph2")}</p>
        <figure>
          <img
            src="https://images.unsplash.com/photo-1550305080-4e029753abcf?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt="nc blog"
            className="rounded-2xl"
          />
          {/* <figcaption>
            .
          </figcaption> */}
        </figure>
        <p>{i18n.t("miceParagraph3")}</p>
        <p>{i18n.t("miceParagraph4")}</p>

      </div>
    );
  };

 

  return (
    <div className="nc-PageSingle pt-8 lg:pt-16 ">
      <Helmet>
        <title>Esmeralda || MICE</title>
      </Helmet>
      <Heading desc="" isCenter>
        MICE
      </Heading>

      {/* {renderHeader()} */}
      {/* <NcImage
        className="w-full max-w-xl rounded-xl"
        containerClassName="container my-10 sm:my-12 flex justify-center"
        src="https://images.unsplash.com/photo-1511578314322-379afb476865?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
      /> */}

      <div className="nc-SingleContent container space-y-10">
        {renderContent()} <br />
       
      </div>
      
    </div>
  );
};

export default BlogSingle;
