import rightImg from "images/about2.png";
import React, { FC } from "react";
import SectionFounder from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import i18n from "i18next";

export interface PageAboutProps {
  className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>Esmeralda</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-16 lg:py-28 space-y-4 lg:space-y-8">
        <SectionHero
          rightImg={rightImg}
          heading={"👋" + i18n.t("aboutUs")}
          btnText=""
          subHeading= {i18n.t("aboutUsParagraph1")}
        />

        {/* Adjusted the space between paragraphs */}
        <div className="space-y-10">

          
          <p className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
            {i18n.t("aboutUsParagraph2")}
          </p>

          <p className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
            {i18n.t("aboutUsParagraph3")}
          </p>

          <p className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
            {i18n.t("aboutUsParagraph4")}
          </p>

          <ul className="list-inside list-disc pl-5 text-neutral-600 dark:text-neutral-400 text-base xl:text-base">
            <li>{i18n.t("aboutUsParagraphItem1")}</li>
            <li>{i18n.t("aboutUsParagraphItem2")}</li>
            <li>{i18n.t("aboutUsParagraphItem3")}</li>
            <li>{i18n.t("aboutUsParagraphItem4")}</li>
            <li>{i18n.t("aboutUsParagraphItem5")}</li>
            <li>{i18n.t("aboutUsParagraphItem6")}</li>
          </ul>

          <p className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
          {i18n.t("aboutUsParagraph5")}
          </p>

          <p className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
          {i18n.t("aboutUsParagraph6")}
          </p>

          <p className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 italic">
          {i18n.t("aboutUsParagraph7")} <br></br>{i18n.t("aboutUsParagraph8")}
          </p>

        </div>

        <br />

        {/* <SectionFounder /> */}

        <SectionStatistic />
      </div>
    </div>
  );
};

export default PageAbout;
