import React, { FC, useEffect, useMemo } from "react";
import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide";
import { TaxonomyType } from "data/types";
import CardCategory3 from "components/CardCategory3/CardCategory3";
import CardCategory4 from "components/CardCategory4/CardCategory4";
import NextPrev from "shared/NextPrev/NextPrev";
import CardCategory5 from "components/CardCategory5/CardCategory5";
import useNcId from "hooks/useNcId";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useTranslation } from "react-i18next"; // Import useTranslation for i18n support

// Define props for SectionSliderNewCategories component
export interface SectionSliderNewCategoriesProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  subHeading?: string;
  categories?: TaxonomyType[];
  categoryCardType?: "card3" | "card4" | "card5";
  itemPerRow?: 3 | 4 | 5;
  sliderStyle?: "style1" | "style2";
  uniqueClassName: string;
  buttonLink?: string;
}

// Sample categories data (DEMO_CATS_YURTDISI)
const DEMO_CATS_YURTDISI: TaxonomyType[] = [
  {
    id: "1",
    href: "/Western_black_sea_tour_5_6",
    name: "westernBlackSeaTour", // Translation key
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.unsplash.com/photo-1696149333862-6999ecb65d0e?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    id: "2",
    href: "/listing-stay",
    name: "russia", // Translation key
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/7740160/pexels-photo-7740160.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "3",
    href: "/listing-stay",
    name: "balkans", // Translation key
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/739407/pexels-photo-739407.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "4",
    href: "/listing-stay",
    name: "italy", // Translation key
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/460672/pexels-photo-460672.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
  },
  {
    id: "5",
    href: "/listing-stay",
    name: "Tokyo", // Translation key
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/4151484/pexels-photo-4151484.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
  },
  {
    id: "6",
    href: "/listing-stay",
    name: "Maldives", // Translation key
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/3250613/pexels-photo-3250613.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
];

const SectionSliderNewCategories: FC<SectionSliderNewCategoriesProps> = ({
  heading = "Tours",
  subHeading = "Filter tours",
  className = "",
  itemClassName = "",
  categories = DEMO_CATS_YURTDISI,
  itemPerRow = 3,
  categoryCardType = "card3",
  sliderStyle = "style1",
  uniqueClassName,
  buttonLink = "/contact",
}) => {
  const { t } = useTranslation(); // Translation function

  // Unique class for styling
  const UNIQUE_CLASS =
    "SectionSliderNewCategories__" + uniqueClassName + useNcId();

  // Glide.js initialization
  let MY_GLIDEJS = useMemo(() => {
    return new Glide(`.${UNIQUE_CLASS}`, {
      perView: itemPerRow,
      gap: 32,
      bound: true,
      breakpoints: {
        1280: {
          perView: itemPerRow - 1,
        },
        1024: {
          gap: 20,
          perView: itemPerRow - 1,
        },
        768: {
          gap: 20,
          perView: itemPerRow - 2,
        },
        640: {
          gap: 20,
          perView: itemPerRow - 3,
        },
        500: {
          gap: 20,
          perView: 1.3,
        },
      },
    });
  }, [UNIQUE_CLASS]);

  useEffect(() => {
    setTimeout(() => {
      MY_GLIDEJS.mount();
    }, 100);
  }, [MY_GLIDEJS, UNIQUE_CLASS]);

  // Function to render cards based on selected card type
  const renderCard = (item: TaxonomyType, index: number) => {
    // Return the translated category name and pass it into the Card component
    switch (categoryCardType) {
      case "card3":
        return <CardCategory3 taxonomy={{ ...item, name: t(item.name) }} />;
      case "card4":
        return <CardCategory4 taxonomy={{ ...item, name: t(item.name) }} />;
      case "card5":
        return <CardCategory5 taxonomy={{ ...item, name: t(item.name) }} />;
      default:
        return <CardCategory3 taxonomy={{ ...item, name: t(item.name) }} />;
    }
  };

  return (
    <div className={`nc-SectionSliderNewCategories ${className}`}>
      <div className={`${UNIQUE_CLASS} flow-root`}>
        <Heading
          desc={subHeading}
          hasNextPrev={sliderStyle === "style1"}
          isCenter={sliderStyle === "style2"}
        >
          {heading}
        </Heading>
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {categories.map((item, index) => (
              <li key={index} className={`glide__slide ${itemClassName}`}>
                {/* Pass translated name to renderCard */}
                {renderCard(item, index)}
              </li>
            ))}
          </ul>
        </div>

        {sliderStyle === "style2" && (
          <NextPrev className="justify-center mt-16" />
        )}
      </div>
    </div>
  );
};

export default SectionSliderNewCategories;
