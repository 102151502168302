import { DEMO_POSTS } from "data/posts";
import { PostDataType } from "data/types";
import React from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Comment from "shared/Comment/Comment";
import NcImage from "shared/NcImage/NcImage";
import SocialsList from "shared/SocialsList/SocialsList";
import Textarea from "shared/Textarea/Textarea";
import { Helmet } from "react-helmet";
import Header3 from "components/Header/Header3";
import Heading from "components/Heading/Heading";
import i18n from "i18next";

const BlogSingle = () => {

  const renderContent = () => {
    return (


      <div
        id="single-entry-content"
        className="prose dark:prose-invert prose-sm !max-w-[55rem] sm:prose lg:prose-lg mx-auto dark:prose-dark" style={{ fontSize: '1rem' }}
      >
        <p><strong>
          Kişisel Verilerin Korunması ve İşlenmesi Hakkında Aydınlatma Metni</strong>
        </p>

        <p>Bu aydınlatma metni, veri sorumlusu sıfatıyla hareket eden Esmeralda Uluslararası Turizm Ticaret Danışmanlık ve Taahhüt Ltd. Şti. tarafından yönetilmekte olan “esmeraldatur.com” adresinde yer alan internet sitesinin, kullanımı sırasında elde edilen ve/veya üçüncü kişilerden alınan kişisel verilerin işlenmesine ilişkin detaylı açıklama ve bilgilendirmede bulunmak amacıyla 6698 sayılı KVK Kanununa uygun olarak hazırlanmıştır.</p>

        <p>Şirketimiz 1618 sayılı Seyahat Acentaları ve Seyahat Acentaları Birliği Kanunu,05.10.2007 tarihli Seyahat Acenteleri Yönetmeliği, 14.01.2015 tarihli Paket Tur Sözleşmeleri Yönetmeliği ve ilgili sair turizm, seyahat acenteleri ve turlara dair mevzuat uyarınca Türkiye içerisinde ve Türkiye dışarısında turlar düzenlemekte, seyahat programları oluşturmakta, vb. organizasyonları yapmakta ve sair turizm ve seyahat faaliyetleri ile iştigal etmektedir. Kullanıcılar, tur ve hizmet satın almak üzere isim, mobil ve sabit iletişim bilgileri, e-posta adresi, satın alınacak olan tur ve/veya hizmet ödemesinin türü, diğer katılımcı bilgileri gibi konularda Esmeralda Tur’a doğru ve eksiksiz bilgi vermekle yükümlüdür. Siteye giriş yapan, siteyi ziyaret eden, herhangi bir şekilde kullanan, siteye erişen ve/veya herhangi başkaca bir şekilde bağlanan tüm kullanıcılar aşağıdaki kuralları peşinen beyan, kabul ve taahhüt ederler.</p>

        <p>
          Şirketimiz tarafından sunulan kişisel/kurumsal seyahat hizmetleri, toplantı ve etkinlik yönetimi, yurtiçi ve yurtdışından gelen müşterilere seyahat hizmetleri, vize hizmetleri, rezervasyon, vb işlemleri süreçlerinde işlenen “kimlik, iletişim, müşteri işlem, fiziksel mekan güvenliği, işlem güvenliği, finans, pazarlama, görsel ve iitsel kayıtlar, sağlık bilgileri” kategorilerine ait kişisel verileriniz aşağıdaki amaçlarla sınırlı olarak işlenmektedir:
        </p>

        <ul>
          <li>Operasyonel işlemlerin yürütülebilmesi için gerekli hizmetlerin verilebilmesi amacıyla</li>
          <li>Satış süreçlerinin yürütülmesi, müşteri ilişkileri yönetimi ile talep ve şikayetlerin değerlendirilmesi, kampanya, promosyon veya tantım süreçlerinin yürütülmesi,</li>
          <li>Farklı kanallardan (online, ofis satış vb.) müşterilerimize gezi, tur, diğer etkinlikler kapsamında hizmetlerimizin sunulabilmesi,</li>
          <li>Satış sürecinin tamamlayıcı işlemi olarak fatura, irsaliye vb. yasal belgelerin düzenlenebilmesi, müşterimize iletilebilmesi ve tahsilat sürecinin yönetilebilmesi,</li>
          <li>Vize başvuruları ile ilgili gerekli işlemlerin yürütülebilmesi,</li>
          <li>Seyahat ve konaklama güvenliğinin temin edilerek sağlık koşullarının gereği gibi sağlanabilmesi, </li>
          <li>Kargo gönderimi, sipariş, bilet/rezervasyon dökümü teslimi gibi satış sürecinin operasyonlarının yönetimi ile bu konularda telefon sms ve e-posta gibi iletişim kanalları üzerinden müşterilerimize bilgi iletilebilmesi,</li>
          <li>Seyahat acentaleri mevzuatı, Tüketici hukuku ve elektronik ticaret hukukunda düzenlenen yükümlülüklerin yerine getirilebilmesi,</li>
          <li>Telefon, e-mail, sosyal medya, web portal, web chat, whatsapp vb. sosyal iletişim kanalları, yüz yüze gibi iletişim araçları vasıtasıyla üyelik/abonelik/satış öncesi soru ve şikayetlerin yanıtlanabilmesi, müşteri veya müşteri adayımız tarafından talep edilen bilgilerin paylaşılabilmesi, ihtiyaç duyulabilecek hesaplamaların yapılabilmesi, vb</li>
          <li>Satış öncesi, esnası ve sonrasında geri bildirimlerin değerlendirilebilmesi ve sonuçlandırılabilmesi hizmet kalitesinin artırılması amacıyla müşteri memnuniyeti araştırmasının yapılabilmesi, müşteri deneyim ve taleplerinin takip edilebilmesi, düzenlenebilmesi ve iyileştirebilmesi,</li>
          <li>İnternet üzerinden gerçekleştirilen üyelik/alışveriş gibi işlemlerde tutulması gerekli olan bilgilerin saklanabilmesi,</li>
          <li>Sertifikasyonun sağlanabilmesi için akredite olmuş bağımsız firmalar tarafından denetim gerçekleştirilmesi, sonuçların tarafımıza bildirilebilmesi,</li>
          <li>Hukuki ihtilaflar durumunda tm hizmet geçmişi kayıtlarının delil olarak sunulabilmesi. Kişisel verileriniz, aktarılmasını gerektiren sebeplerle sınırlı olmak üzere aşağıdaki alıcı grupları ile paylaşılmaktadır:</li>
          <li>Hizmet verdiğimiz alanlarda müşterilerimizin kayıtlarının tutulabilmesi, gerekli önlem ve tetkiklerin sağlanabilmesi, iletiim kurulabilmesi ve zorunlu tüm işlemlerin yapılabilmesi için ilgili resmi makamlar, büyükelçilik ve konsolosluk yetkilileri, otel, havayolu şirketi, sigorta şirketi, vb. tedarikçilerimiz,</li>
          <li>Satış ile ilgili tüm finansal, mali ve vergisel süreçlerin yürütülebilmesi için elektronik ödeme sistemi ve vergilendirme hizmeti veren şirketler, bankalar, mali denetim firmaları,</li>
          <li>Hukuki haklarımızın kullanılabilmesi için avukatlarımız ve adli merciler; Satış operasyonlarının yürütülebilmesi için kargo/posta şirketleri,</li>
          <li>Şirketimizin yasalar karşısındaki yükümlülüklerinin ifası amacıyla mevzuat hükümleri izin verdiği ve gerektirdiği ölçüde ilgili kamu kurum ve kuruluşları ve yetkilendirmiş oldukları diğer kurumlar,</li>
          <li>Teknik altyapı/elektronik sistemlerin kurulması, geliştirilmesi, bakımına yönelik hizmet veren teknik destek firmaları ile “Elektronik Ticaretin Düzenlenmesi Hakknda Kanun” ve ilgili mevzuat kapsamında ticari elektronik ileti izinlerinin ve şikâyet süreçlerinin ynetilmesi amacıyla kurulan “İleti Yönetim Sistemi” platformu</li>
        </ul>

        <p>Kişisel verileriniz, web sitemizdeki her türlü forma yapılan bilgi girişleri, web chat gibi online mesajlaşma portalları, sosyal medya, whatsapp vb. sosyal iletişim kanalları, telefon ve e-mail yoluyla bilgi paylaımı ve ofisimizde yüz yüze aktarılan bilgilerin sisteme kaydedilmesi yoluyla toplanmakta olup 6698 sayıl Kişisel Verilerin Korunması Kanunun 5/2 a, c, ç, e, f bentlerinde belirtilen “kanunlarda açıkça öngörülmesi, bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması kaydıyla sözleşmenin taraflarına ait kişisel verilerin işlenmesinin gerekli olmas, veri sorumlusunun hukuki yükümlülüğünü yerine getirebilmesi için zorunlu olması, bir hakkın tesisi kullanılması veya korunması için veri işlemenin zorunlu olması ve ilgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla veri sorumlusunun meşru menfaatleri için veri işlemenin zorunlu olması” hukuki sebeplerine bağlı olarak işlenmektedir.</p>
        <p>“Elektronik Ticaretin Düzenlenmesi Hakknda Kanun” ve ilgili tüm alt mevzuatı uyarınca tarafınızla iletişime geçilebilmesi ile bu anlamda e-posta ve smsler gönderilebilmesi amacıyla, Kişisel Verilerin Korunması Kanunun 5/1 hükmü uyarınca “AÇIK RIZA” kapsamında işlenecek kişisel veriler ise; Kullanım/alışveriş alışkanlıklarınızın tespiti, segmente edilmesi ile hediye, indirim, yeni imkân ve özel tekliflerin iletilebilmesi, tüketici tercih analizleri ile ürün-hizmet tanıtımı, bilgilendirme, reklam, kampanya gibi kişiye zel pazarlama aktiviteleri için işlenmekte; gönderimlerin gerçekleştirilebilmesi amacıyla sözleşme ilikisinde olunan SMS ve e-posta gönderim hizmeti veren şirketler ve pazarlama konusunda danışmanlık hizmeti veren şirketler ile paylaşılmaktadır. Kanunun 6/2 hükmü uyarınca “AÇIK RIZA” kapsamında işlenecek sağlık verileri ise; Düzenlenen tur, organizasyon ve seyahatlerde insan sağlığının korunabilmesi için zorunlu olan minimum düzeyde bilginin alınması ve bu amacı gerçekleştirmeye yönelik olarak gerekli önlemlerin alınabilmesi için ilgili tur, organizasyon ve seyahat şirketlerindeki paylaşımı zaruri olan görevli personel ile paylaşılmaktadır. Kanunun ilgili kişinin haklarını düzenleyen 11. maddesi kapsamındaki taleplerinizi “Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğe” göre aşağıda detayları bulunan adresimize yazılı olarak iletebilirsiniz.</p>

        <p>Bu siteyi kullanarak mevcut uygulamaları kabul etmektesiniz. Bu uygulamaların koşullarını kabul etmiyorsanız lütfen bu siteyi kullanmayınız ve kişisel nitelikli herhangi bir veri vermeyiniz. Ayrıntılı bilgi almak için KişiselVerileri Koruma Kurumu’nun internet sayfasını ziyaret edebilirsiniz. İbu uygulamalar, periyodik olarak güncellenmektedir. Değişiklikler, yayınlama tarihinden itibaren geçerli olacaktır. Lütfen bu sayfayı düzenli aralıklarla ziyaret ediniz.</p>
        <p>Esmeralda Uluslararası Turizm Ticaret Danışmanlık ve Taahhüt Ltd. Şti.</p>
        <p>Necatibey Caddesi No: 13/9 Kızılay /Ankara</p>
        <p>Telefon : 0.312 231 88 55 Pbx</p>

        <p><strong>
          Esmeralda Turizm Kişisel Verilerin İşlenmesine İlişkin Müşteri Açık Rıza Beyanı</strong>
        </p>

        <p>Esmeralda Uluslararası Turizm Ticaret Danşmanlık ve Taahhüt Ltd. Şti. tarafından iletilen aydınlatma metni ile tarafıma, işlenecek kişisel veri kategorileri, işlenecek kişisel veriler, işlenme amaçları, aktarılacağı kişiler, toplanma yöntemleri ve hukuki sebepleri, veri sorumlusunun kimliği ve sahip olduğum haklar ayrıntılı ve anlaşılır bir biçimde anlatılmış ve tarafımca söz konusu bu bilgilendirme metni okunmuştur. Bu kapsamda daha detaylı bilgilere “esmeraldatur.com” adresinde yer alan kişisel veri saklama ve imha politikası ile VERBİS sistemindeki kayıtlardan da ulaşabileceğim belirtilmiştir.</p>

        <p>Aydınlatma metninde belirtilen tüm hususlar ile birlikte; Kullanım/alışveriş alışkanlıklarımın tespiti, segmente edilmesi, mevcut veriler üzerinde yapılan analizlerden yeni verilerin elde edilmesi ile hediye, indirim, yeni imkân ve özel tekliflerin tarafıma iletilebilmesi, tüketici tercih analizleri ile ürün-hizmet tanıtımı, bilgilendirme, reklam, kampanya gibi kişiye özel pazarlama aktiviteleri iin “Elektronik Ticaretin Düzenlenmesi Hakkında Kanun” ve ilgili tüm alt mevzuatı uyarınca şahsımla iletişime geçilebilmesi ve tarafıma bu anlamda e-posta ve smsler gönderilebilmesi amaçlarıyla sınırlı olmak üzere “ad soy ad, yaşadığım şehir/ülke, doğum tarihi, e-mail adresi, telefon numarası, cinsiyet, IP adresi, vb” bilgilerimin işlenmesine; bu verilerimin sözleşme ilişkisinde olunan sms ve e-posta gönderim hizmeti veren şirketler ve pazarlama konusunda danışmanlık hizmeti veren şirketler ile paylaşılmasına,</p>

        <p>Sağlık verilerinin ise; Düzenlenen tur, organizasyon ve seyahatlerde insan sağlığının korunabilmesi için zorunlu olan minimum dzeyde bilginin alınması ve bu amacı gerçekleştirmeye yönelik olarak gerekli önlemlerin alınabilmesi için ilgili tur, organizasyon, sigorta şirketi ve seyahat şirketlerindeki paylaımı zaruri olan görevli personel ile paylaşılmasına;</p>

        <p>Bu beyanımın tüm anlam ve sonuçlarının farkında olarak, gelecekteki olası sonuçlarının bilincinde, özgür iradem ile onay veriyorum.</p>

      </div>

    );
  };



  return (
    <div className="nc-PageSingle pt-8 lg:pt-16 ">
      <Helmet>
        <title>Esmeralda</title>
      </Helmet>
      <Heading desc="" isCenter>
        Çerezler & Gizlilik Sözleşmesi
      </Heading>

      {/* {renderHeader()} */}
      {/* <NcImage
        className="w-full max-w-xl rounded-xl"
        containerClassName="container my-10 sm:my-12 flex justify-center"
        src="https://images.unsplash.com/photo-1511578314322-379afb476865?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
      /> */}

      <div className="nc-SingleContent container space-y-10">
        {renderContent()} <br />

      </div>

    </div>
  );
};

export default BlogSingle;
