import { MegamenuItem, NavItemType } from "shared/Navigation/NavigationItem";
import i18n from "i18next"; // Import i18n for translations
import ncNanoId from "utils/ncNanoId";
import __megamenu from "./jsons/__megamenu.json";
import micePng from "images/mice.png";
import hotelPng from "images/hotel1.png";
import transferPng from "images/transfer1.png";


// Services array using translations
export const services: MegamenuItem[] = [
  {
    id: ncNanoId(),
    image: micePng,
    title: i18n.t("mice"), // Use i18n to translate title
    items: __megamenu.map((i) => ({
      id: ncNanoId(),
      href: "#",
      name: i18n.t(i.AppName), // Translate AppName dynamically
    })),
  },
  {
    id: ncNanoId(),
    image: hotelPng,
    title: i18n.t("hotels"), // Use i18n to translate title
    items: __megamenu.map((i) => ({
      id: ncNanoId(),
      href: "#",
      name: i18n.t(i.City), // Translate City dynamically
    })),
  },
  {
    id: ncNanoId(),
    image: transferPng,
    title: i18n.t("transfer"), // Use i18n to translate title
    items: __megamenu.map((i) => ({
      id: ncNanoId(),
      href: "#",
      name: i18n.t(i.Country), // Translate Country dynamically
    })),
  },
];

// Main navigation items array
export const NAVIGATION_DEMO: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/",
    name: "homepage",
  },
  {
    id: ncNanoId(),
    href: "/About",
    name: "aboutUs",
  },
  {
    id: ncNanoId(),
    href: "javascript:void(0)",
    name: "tours",
    type: "dropdown",
    isNew: true,
    children: [
      {
        id: ncNanoId(),
        href: "/Turkiye_tours",
        name: "localTours",
      },
      {
        id: ncNanoId(),
        href: "/International_tours",
        name: "internationalTours",
      },
      // {
      //   id: ncNanoId(),
      //   href: "/createYourOwnTour",
      //   name: "createYourOwnTour",
      // },
    ],
  },
  {
    id: ncNanoId(),
    href: "/Contact",
    name: "contact",
  },
];

