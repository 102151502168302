import React, { FC, Fragment, useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import i18n from "i18next";
import CommentListing from "components/CommentListing/CommentListing";
import FiveStartIconForRate from "components/FiveStartIconForRate/FiveStartIconForRate";
import StartRating from "components/StartRating/StartRating";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import LikeSaveBtns from "components/LikeSaveBtns";
import SectionDateRange from "../ListingDetailPage/SectionDateRange";
import StayDatesRangeInput from "../ListingDetailPage/listing-stay-detail/StayDatesRangeInput";
import { useLocation, useNavigate } from "react-router-dom";
import { Amenities_demos, PHOTOS_OTTOMAN_CAPITALS, Ottoman_capitals_tour_includes, Ottoman_capitals_tour_excludes } from "../ListingDetailPage/listing-stay-detail/constant";
import { Dialog as HeadLessDialog, Transition } from "@headlessui/react";
import { ArrowRightIcon, Squares2X2Icon } from "@heroicons/react/24/outline";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import ButtonCircle from "shared/Button/ButtonCircle";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import DetailPagetLayout from "../ListingDetailPage/Layout";
import GuestsInput from "../ListingDetailPage/listing-stay-detail/GuestsInput";
import { color } from "framer-motion";
import { Tab } from "@headlessui/react";
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";


const StayDetailPageContainer: FC<{}> = () => {
  const { t, i18n } = useTranslation(); // Get translation function
  const [categories, setCategories] = useState<string[]>([]); // State to store categories
  const [isOpenModalAmenities, setIsOpenModalAmenities] = useState(false);

  const thisPathname = useLocation().pathname;
  const router = useNavigate();



  useEffect(() => {
    document.title = "Esmeralda";
  }, [t]); // Add t as a dependency to update title when the language changes

  // Populate categories when the component mounts or i18n changes
  useEffect(() => {
    setCategories([
      t("fullItinerary")
      // t("overview"),
      // t("tourDetails")
    ]);
  }, [t]); // Re-run the effect when the translation function (t) changes



  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState<DialogProps['scroll']>('paper');
  const handleClickOpen = (scrollType: DialogProps['scroll']) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const itineraryDays = [
    { title: "ottomanCapitalsTourDay1Title", paragraph: "ottomanCapitalsTourDay1Paragraph" },
    { title: "ottomanCapitalsTourDay2Title", paragraph: "ottomanCapitalsTourDay2Paragraph" },
    { title: "ottomanCapitalsTourDay3Title", paragraph: "ottomanCapitalsTourDay3Paragraph" },
    { title: "ottomanCapitalsTourDay4Title", paragraph: "ottomanCapitalsTourDay4Paragraph" },
    { title: "ottomanCapitalsTourDay5Title", paragraph: "ottomanCapitalsTourDay5Paragraph" }
  ];


  function closeModalAmenities() {
    setIsOpenModalAmenities(false);
  }

  function openModalAmenities() {
    setIsOpenModalAmenities(true);
  }

  const handleOpenModalImageGallery = () => {
    router(`${thisPathname}/?modal=PHOTO_TOUR_SCROLLABLE`);
  };

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap !space-y-6">
        {/* 1 */}
        <div className="flex justify-between items-center">
          <Badge name={t("localTours")} />
        </div>

        {/* 2 */}
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
          {i18n.t("ottomanCapitalsTour") + " | " + i18n.t("4nights5days")}
        </h2>

        {/* 3 */}
        <div className="flex items-center space-x-4">
          <span>
            <i className="las la-utensils"></i>
            <span className="ml-1">{i18n.t("halfBoard")}</span>
          </span>
        </div>

        {/* 4 */}

        {/* 5 */}
        <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

        {/* 6 */}
        <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-5 text-sm text-neutral-700 dark:text-neutral-300">
          <div className="flex items-center space-x-3 ">
            <i className=" las la-landmark text-2xl "></i>
            <span className="">
              <span className="hidden sm:inline-block">{i18n.t("museumsAndRuins")}</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-hotel text-2xl"></i>
            <span className=" ">
              <span className="hidden sm:inline-block">{i18n.t("halfBoard")}</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-hiking text-2xl"></i>
            <span className=" ">
              <span className="hidden sm:inline-block">{i18n.t("tourGuide")}</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-coffee  text-2xl"></i>
            <span className=" ">
              <span className="hidden sm:inline-block">{i18n.t("vehicleCatering")}
              </span>
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">{i18n.t("details")}</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300">
          <div>
            <Tab.Group>
              <Tab.List className="flex space-x-1 overflow-x-auto">
                {categories.map((item) => (
                  <Tab key={item} as={Fragment}>
                    {({ selected }) => (
                      <button
                        className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 capitalize rounded-full focus:outline-none ${selected
                          ? "bg-orange-500 text-secondary-50 "
                          : "text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                          } `}
                      >
                        {item}
                      </button>
                    )}
                  </Tab>
                ))}
              </Tab.List>

              <Tab.Panels>
                <Tab.Panel className="mt-8">
                  <div>
                    <div>
                      <ButtonSecondary onClick={handleClickOpen('paper')}>
                        <span className="text-sm">{t("detailedItinerary")}</span>
                      </ButtonSecondary>
                    </div>
                    <br />

                    <Accordion defaultExpanded>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <span className="text-sm font-semibold block">
                          {t("ottomanCapitalsTourDay1Title")}
                        </span>
                      </AccordionSummary>
                      <AccordionDetails>
                        <span className="text-sm">
                          {t("ottomanCapitalsTourDay1Paragraph")}
                        </span>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  <br />

                  <div>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <span className="text-sm font-semibold block">
                          {t("ottomanCapitalsTourDay2Title")}
                        </span>
                      </AccordionSummary>
                      <AccordionDetails>
                        <span className="text-sm">
                          {t("ottomanCapitalsTourDay2Paragraph")}
                        </span>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  <br />

                  <div>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3-content"
                        id="panel3-header"
                      >
                        <span className="text-sm font-semibold block">
                          {t("ottomanCapitalsTourDay3Title")}
                        </span>
                      </AccordionSummary>
                      <AccordionDetails>
                        <span className="text-sm">
                          {t("ottomanCapitalsTourDay3Paragraph")}
                        </span>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  <br />

                  <div>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel4-content"
                        id="panel4-header"
                      >
                        <span className="text-sm font-semibold block">
                          {t("ottomanCapitalsTourDay4Title")}
                        </span>
                      </AccordionSummary>
                      <AccordionDetails>
                        <span className="text-sm">
                          {t("ottomanCapitalsTourDay4Paragraph")}
                        </span>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  <br />

                  <div>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel5-content"
                        id="panel5-header"
                      >
                        <span className="text-sm font-semibold block">
                          {t("ottomanCapitalsTourDay5Title")}
                        </span>
                      </AccordionSummary>
                      <AccordionDetails>
                        <span className="text-sm">
                          {t("ottomanCapitalsTourDay5Paragraph")}
                        </span>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </Tab.Panel>
                <Tab.Panel className="mt-8">
                  <div >
                    <span className="text-sm font-semibold block">
                      Şehir İçi Turu - Kaleiçi - Yat Limanı - Side - Manavgat Şelalesi - Aspendos - Olimpos - Çıralı - Yanartaş - Lara Plajları - Düden Şelalesi - Kemer - Phaselis - Termessos - easternBlackSea Müzesi - Konyaaltı Plajı
                    </span>
                  </div>
                </Tab.Panel>
              </Tab.Panels>

            </Tab.Group>
          </div>
        </div>
        <Dialog open={open} onClose={handleClose} scroll={scroll} aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description">
          <DialogTitle id="scroll-dialog-title">
            <span className="text-l font-semibold">{t("detailedItinerary2")}</span>
          </DialogTitle>

          <DialogContent dividers={scroll === 'paper'}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              {/* Dynamically populate itinerary details */}
              {itineraryDays.map((day, index) => (
                <div key={index}>
                  <h3>{t(day.title)}</h3>
                  <p>{t(day.paragraph)}</p>
                  <br />
                  <hr />
                </div>
              ))}
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <ButtonSecondary onClick={handleClose}>{t("closeButton")}</ButtonSecondary>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  const renderSection3 = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Amenities </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {` About the property's amenities and services`}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* 6 */}
        <div className="grid grid-cols-1 xl:grid-cols-3 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
          {Amenities_demos.filter((_, i) => i < 12).map((item) => (
            <div key={item.name} className="flex items-center space-x-3">
              <i className={`text-3xl las ${item.icon}`}></i>
              <span className=" ">{item.name}</span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderMotalAmenities = () => {
    return (
      <Transition appear show={isOpenModalAmenities} as={Fragment}>
        <HeadLessDialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalAmenities}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <HeadLessDialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-screen w-full max-w-4xl">
                <div className="inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="headlessui-dialog-title-70"
                    >
                      Amenities
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalAmenities} />
                    </span>
                  </div>
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                    {Amenities_demos.filter((_, i) => i < 1212).map((item) => (
                      <div
                        key={item.name}
                        className="flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                      >
                        <i
                          className={`text-4xl text-neutral-6000 las ${item.icon}`}
                        ></i>
                        <span>{item.name}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </HeadLessDialog>
      </Transition>
    );
  };



  const renderSection5 = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">{t("servicesIncluded")}</h2>
          {/* <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
          {t("servicesIncluded")}
        </span> */}
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* 6 */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
          {Ottoman_capitals_tour_includes
            .filter((_, i) => i < 12) // Optionally filter the number of items
            .map((item) => (
              <div key={item.name} className="flex items-center space-x-3">
                <i className="las la-check-circle text-2xl"></i>
                <span>{t(item.name)}</span> {/* Dynamically translate the key */}
              </div>
            ))}
        </div>
      </div>
    );
  };

  const renderSection6 = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">{t("servicesExcluded")}</h2> {/* Translate "Exclude" */}
          {/* <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
          {t("excludedInThePrice")} 
        </span> */}
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* 6 */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
          {Ottoman_capitals_tour_excludes
            .filter((_, i) => i < 12) // Optionally filter the number of items
            .map((item) => (
              <div key={item.name} className="flex items-center space-x-3">
                <i className="las la-circle text-2xl"></i>
                <span>{t(item.name)}</span> {/* Dynamically translate each exclude item */}
              </div>
            ))}
        </div>
      </div>
    );
  };



  const renderSection8 = () => {
    return (
      <div className="listingSection__wrap">

        {/* <div className="border-b border-neutral-300 dark:border-neutral-700" /> */}
        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">{t("note")}</h4>
          <div className="prose sm:prose">
            <ul className="mt-3 text-neutral-500 dark:text-neutral-400 space-y-2">
              <li>
                {t("noteParagraph")}
              </li>
            </ul>
          </div>
        </div>

      </div>
    );
  };

  const renderSidebar = () => {
    return (
      <div className="listingSectionSidebar__wrap shadow-xl">
        {/* PRICE */}
        <div className="flex space-y-1" style={{ width: "400px" }}>
          <span className="text-xl font-semibold">
            <span className="text-base font-normal text-neutral-500 dark:text-neutral-400">
              {i18n.t("tourBookingParagraph")} {" "}
              <strong>
                <a href="mailto:info@esmeraldatur.com" style={{ color: "rgb(249, 115, 22)" }}>
                  info@esmeraldatur.com
                </a>
              </strong>
            </span>
          </span>
        </div>

        {/* SUBMIT */}
        <ButtonPrimary href={"/contact"}> {i18n.t("contactUs")}</ButtonPrimary>
      </div>
    );
  };

  return (
    <div className="nc-ListingStayDetailPage">
      {/*  HEADER */}
      <header className="rounded-md sm:rounded-xl">
        <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
          <div
            className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer "
            onClick={handleOpenModalImageGallery}
          >
            <img
              className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
              src={PHOTOS_OTTOMAN_CAPITALS[0]}
              alt=""
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
            />
            <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
          </div>
          {PHOTOS_OTTOMAN_CAPITALS.filter((_, i) => i >= 1 && i < 5).map((item, index) => (
            <div
              key={index}
              className={`relative rounded-md sm:rounded-xl overflow-hidden ${index >= 3 ? "hidden sm:block" : ""
                }`}
            >
              <div className="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5">
                <img
                  className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
                  src={item || ""}
                  alt=""
                  sizes="400px"
                />
              </div>

              {/* OVERLAY */}
              {/* <div
                className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                onClick={handleOpenModalImageGallery}
              /> */}
            </div>
          ))}

          {/* <button
            className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 hover:bg-neutral-200 z-10"
            onClick={handleOpenModalImageGallery}
          >
            <Squares2X2Icon className="w-5 h-5" />
            <span className="ml-2 text-neutral-800 text-sm font-medium">
              Show all photos
            </span>
          </button> */}
        </div>
      </header>

      {/* MAIN */}
      <main className=" relative z-10 mt-11 flex flex-col lg:flex-row ">
        {/* CONTENT */}
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
          {renderSection1()}
          {renderSection2()}
          {/* {renderSection3()} */}
          {/* {renderSection4()} */}
          {/* {renderSection5()}
          {renderSection6()} */}
          {/* <SectionDateRange /> */}
          {/* {renderSection7()} */}
          {renderSection8()}

        </div>

        {/* SIDEBAR */}
        <div className="hidden lg:block flex-grow mt-14 lg:mt-0">
          <div className="sticky top-28">{renderSidebar()}</div>
        </div>
      </main>

    </div>
  );
};

export default function Ottoman_capitals_tour() {
  return (

    <DetailPagetLayout>
      <StayDetailPageContainer />
      <BgGlassmorphism />
    </DetailPagetLayout>
  );
}
