import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import SiteHeader from "containers/SiteHeader";
import Footer from "shared/Footer/Footer";

import FooterNav from "components/FooterNav";
import useWindowSize from "hooks/useWindowResize";

// Containers (Pages)
import PageHome from "containers/PageHome/PageHome";

import Page404 from "containers/Page404/Page404";
import PageContact from "containers/PageContact/PageContact";
import PageAbout from "containers/PageAbout/PageAbout";

// Listings (Stay, Car, Experiences, RealEstate, Flights)
import ListingStayPage from "containers/ListingStayPage/ListingStayPage";
import ListingStayMapPage from "containers/ListingStayPage/ListingStayMapPage";
import ListingStayDetailPage from "containers/ListingDetailPage/listing-stay-detail/ListingStayDetailPage";
import ListingExperiencesPage from "containers/ListingExperiencesPage/ListingExperiencesPage";
import ListingExperiencesMapPage from "containers/ListingExperiencesPage/ListingExperiencesMapPage";
import ListingExperiencesDetailPage from "containers/ListingDetailPage/listing-experiences-detail/ListingExperiencesDetailPage";
import ListingCarDetailPage from "containers/ListingDetailPage/listing-car-detail/ListingCarDetailPage";






import TurkiyeTours from "containers/ListingExperiencesPage/Turkiye_tours";
import InternationalTours from "containers/ListingExperiencesPage/International_tours";
import CreateYourOwnTour from "containers/PageAddListing1/CreateYourOwnTour";

// Tour Pages (Yurtici Turlar & Yurtdisi Turlar)
import Antalya_tour_6_7 from "containers/Yurtici_turlar/Antalya_tour_6_7";
import Antalya_tour from "containers/Yurtici_turlar/Antalya_tour";
import Eastern_anatolia_tour from "containers/Yurtici_turlar/Eastern_anatolia_tour";
import Istanbul_tour from "containers/Yurtici_turlar/Istanbul_tour";
import Cappadocia_tour from "containers/Yurtici_turlar/Cappadocia_tour";
import Western_black_sea_tour_5_6 from "containers/Yurtici_turlar/Western_black_sea_tour_5_6";
import Northern_aegean_tour_6_7 from "containers/Yurtici_turlar/Northern_aegean_tour_6_7";
import Eastern_black_sea_tour_6_7 from "containers/Yurtici_turlar/Eastern_black_sea_tour_6_7";
import Ottoman_capitals_tour from "containers/Yurtici_turlar/Ottoman_capitals_tour";
import Western_and_central_black_sea_tour from "containers/Yurtici_turlar/Western_and_central_black_sea_tour";
import Tillo_tour  from  "containers/Yurtici_turlar/Tillo_tour";
import ListingStayDetailPage_2 from "containers/Yurtici_turlar/ListingStayDetailPage_2";
import moscow_st_petersburg from "containers/Yurtdisi_turlar/moscow_st_petersburg";
import greece from "containers/Yurtdisi_turlar/greece";
import morocco from "containers/Yurtdisi_turlar/morocco";
import balkans from "containers/Yurtdisi_turlar/balkans";
import cuba from "containers/Yurtdisi_turlar/cuba";
import scandinavia from "containers/Yurtdisi_turlar/scandinavia";
import Mice from "containers/BlogPage/Mice";
import PaketTurOtelSozlesmesi from "containers/BlogPage/PaketTur_Otel_Sozlesmesi";
import Gizlilik_Sozlesmesi from "containers/BlogPage/Gizlilik_Sozlesmesi";
import Kvkk from "containers/BlogPage/Kvkk";

// Add Listing Pages
import PageAddListing1 from "containers/PageAddListing1/PageAddListing1";
import PageAddListing2 from "containers/PageAddListing1/PageAddListing2";
import PageAddListing3 from "containers/PageAddListing1/PageAddListing3";
import PageAddListing4 from "containers/PageAddListing1/PageAddListing4";
import PageAddListing5 from "containers/PageAddListing1/PageAddListing5";
import PageAddListing6 from "containers/PageAddListing1/PageAddListing6";
import PageAddListing7 from "containers/PageAddListing1/PageAddListing7";
import PageAddListing8 from "containers/PageAddListing1/PageAddListing8";
import PageAddListing9 from "containers/PageAddListing1/PageAddListing9";
import PageAddListing10 from "containers/PageAddListing1/PageAddListing10";


// Pages Array
export const pages: Page[] = [
  { path: "/", exact: true, component: PageHome },
  { path: "/#", exact: true, component: PageHome },
  { path: "/home-1-header-2", exact: true, component: PageHome },

  { path: "/listing-stay", component: ListingStayPage },
  { path: "/listing-stay-map", component: ListingStayMapPage },
  { path: "/listing-stay-detail", component: ListingStayDetailPage },

  { path: "/Antalya_tour_6_7", component: Antalya_tour_6_7 },
  { path: "/Antalya_tour", component: Antalya_tour },
  { path: "/Eastern_anatolia_tour", component: Eastern_anatolia_tour },
  { path: "/Istanbul_tour", component: Istanbul_tour },
  { path: "/Cappadocia_tour", component: Cappadocia_tour },
  { path: "/Western_black_sea_tour_5_6", component: Western_black_sea_tour_5_6 },
  { path: "/Northern_aegean_tour_6_7", component: Northern_aegean_tour_6_7 },
  { path: "/Eastern_black_sea_tour_6_7", component: Eastern_black_sea_tour_6_7 },
  { path: "/Ottoman_capitals_tour", component: Ottoman_capitals_tour },
  { path: "/Western_and_central_black_sea_tour", component: Western_and_central_black_sea_tour},
  { path: "/Tillo_tour", component: Tillo_tour},
  { path: "/listing-stay-detail-2", component: ListingStayDetailPage_2 },
  { path: "/moscow_st_petersburg", component: moscow_st_petersburg },
  { path: "/greece", component: greece },
  { path: "/morocco", component: morocco },
  { path: "/balkans", component: balkans },
  { path: "/cuba", component: cuba },
  { path: "/scandinavia", component: scandinavia },

  { path: "/createYourOwnTour", component: CreateYourOwnTour },
  { path: "/Turkiye_tours", component: TurkiyeTours },
  { path: "/International_tours", component: InternationalTours },

  { path: "/listing-experiences", component: ListingExperiencesPage },
  { path: "/listing-experiences-map", component: ListingExperiencesMapPage },
  { path: "/listing-experiences-detail", component: ListingExperiencesDetailPage },
 
 

 
  { path: "/listing-car-detail", component: ListingCarDetailPage },




  { path: "/Mice", component: Mice },
  { path: "/PaketTur_Otel_Sozlesmesi", component: PaketTurOtelSozlesmesi  },
  { path: "/Gizlilik_Sozlesmesi", component: Gizlilik_Sozlesmesi },
  { path: "/Kvkk", component: Kvkk },

  { path: "/add-listing-1", component: PageAddListing1 },
  { path: "/add-listing-2", component: PageAddListing2 },
  { path: "/add-listing-3", component: PageAddListing3 },
  { path: "/add-listing-4", component: PageAddListing4 },
  { path: "/add-listing-5", component: PageAddListing5 },
  { path: "/add-listing-6", component: PageAddListing6 },
  { path: "/add-listing-7", component: PageAddListing7 },
  { path: "/add-listing-8", component: PageAddListing8 },
  { path: "/add-listing-9", component: PageAddListing9 },
  { path: "/add-listing-10", component: PageAddListing10 },

  { path: "/contact", component: PageContact },
  { path: "/about", component: PageAbout },

  { path: "/page404", component: Page404 },
];

// Routes Component
const MyRoutes = () => {
  let WIN_WIDTH = useWindowSize().width;
  if (typeof window !== "undefined") {
    WIN_WIDTH = WIN_WIDTH || window.innerWidth;
  }

  return (
    <BrowserRouter>
      <ScrollToTop />
      <SiteHeader />
      

      <Routes>
        {pages.map(({ component, path }) => {
          const Component = component;
          return <Route key={path} element={<Component />} path={path} />;
        })}
        <Route path="*" element={<Page404 />} />
      </Routes>

      {WIN_WIDTH < 768 && <FooterNav />}
      <Footer />
    </BrowserRouter>
  );
};

export default MyRoutes;
