import { DEMO_POSTS } from "data/posts";
import { PostDataType } from "data/types";
import React from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Comment from "shared/Comment/Comment";
import NcImage from "shared/NcImage/NcImage";
import SocialsList from "shared/SocialsList/SocialsList";
import Textarea from "shared/Textarea/Textarea";
import { Helmet } from "react-helmet";
import Header3 from "components/Header/Header3";
import Heading from "components/Heading/Heading";
import i18n from "i18next";

const BlogSingle = () => {

  const renderContent = () => {
    return (


      <div
        id="single-entry-content"
        className="prose dark:prose-invert prose-sm !max-w-[55rem] sm:prose lg:prose-lg mx-auto dark:prose-dark" style={{ fontSize: '1rem' }}
      >

        <p><strong>ESMERALDA TURİZM PAKET TUR / OTEL SÖZLEŞMESİ</strong></p>

        <p><strong>TARAFLAR:</strong></p>
        <ul>
          <li>ESMERALDA Ulus. Tur. Tic. Dan. ve Taah. Ltd. Şti. Necatibey Caddesi No: 13/9 Kızılay/Ankara adresinde ikamet eden ESMERALDA TURİZM (Kısaca Acente olarak anılacaktır) ile diğer tarafta rezervasyonu yaptıran işlem sırasında ad-soyad ve TC Kimlik bilgileri belirtilen (Kısaca Rezervasyon Sahibi olarak anılacaktır) arasında; aşağıda belirtilen hususlarda tam bir anlaşmaya varmışlardır.</li>
        </ul>

        <p><strong>KONU:</strong></p>
        <ul>
          <li>İş bu sözleşme Rezervasyon Sahibinin, Acente vasıtası ile açıklanan koşullar çerçevesindeki Tur / Otel Rezervasyonunu ve/veya alabileceği hizmetleri içerir.</li>
        </ul>

        <p><strong>GENEL HÜKÜMLER:</strong></p>
        <ol>
          <li>Tur / Otel Rezervasyonlarının kesin kayıtları, ilgili hizmetin ücretinin yatırılması ve tur / otel sözleşmesinin imzalanmasıyla yapılır.</li>
          <li>Otel/ tur kayıt işlemleri esnasında tüm katılımcılar doğum tarihi, TC kimlik numarası ile iletişim bilgilerini doğru ve eksiksiz olarak beyan etmek zorundadır. Yanlış beyan edilen her bilgiden doğmuş/ doğacak olan tüm yaptırımlardan katılımcı sorumludur. Yurtiçi/Yurtdışı turlarda nüfus cüzdanlarının/pasaportlarının yolcuların yanlarında bulunması gerekmektedir.</li>
          <li>Sözleşmede imzası bulunmayan ancak sözleşmeye konu tura katılan (adına rezervasyon yapılan) tüketici(ler) de kendi adlarına kayıt yaptırmakla görevlendirdikleri rezervasyon sahibine/tüketiciye, adlarına sözleşme yapma yetkisi verdiklerini, bu sözleşmeyi okuyup imzalaması ile, sözleşmenin bütün şartlarının kendilerince de kabul edilmiş sayılacağını ve sözleşme şartlarına aynen uyacaklarını kabul etmiş sayılırlar. Bu geziye katılan tüketiciler, imzalamış olmasalar dahi, taraflar arasında geçerli olacak bu sözleşme şartlarını katalog, ilanlar, web sayfası, vb ile öğrenmiş, tura/otel rezervasyonuna bu sözleşme şartlarında katılmayı kabul etmişlerdir.</li>
          <li>Özel grup organizasyonları için yapılan anlaşma koşulları ayrıca geçerli olmak ile birlikte, paket turlarda kayıt anında rezervasyon bedelinin en az %25'i, bakiyenin ise turun başlamasından en geç 15 gün öncesine kadar tamamlanması gerekmektedir. Belirtilen süreler içinde ödemelerin gerçekleştirilmemesi durumunda rezervasyon iptal edilir ve tur bedelinin %25'i cayma tazminatı olarak tüketiciye fatura edilir.</li>
          <li>Turlarda buluşma saati, otobüs ulaşımlarında hareket saatinden yarım saat önce ve uçak ulaşımlarında ise hareket saatinden iki saat öncedir. Rezervasyon sahibi acentenin belirlemiş olduğu biniş / iniş noktalarında belirlenen zamanda hazır bulunmalıdır. Rezervasyon Sahibinin zamanında hareket noktasında bulunmaması nedeniyle tura katılamaması durumunda ücret iadesi talep etme hakkı bulunmamaktadır. Rezervasyon Sahibi kendi imkânları ile bedeli kendisi tarafından karşılanmak kaydı ile turun bulunduğu noktaya ulaşarak tura ulaştığı noktadan itibaren katılabilir.</li>
          <li>Turlarımızda ulaşım için kullanılan her türlü kara, hava ve deniz taşıtlarında meydana gelebilecek yaralanma, ölüm ve kayıplarda taşıyıcı firmaların sigortaları geçerlidir.</li>
          <li>Uçaklı ulaşımlarda, havayolu firmasının bagaj kayıp ve hasarı durumunda ilgili havayolu firmasının kuralları geçerli olup, Acentenin sorumluluğunda olmadığı katılımcı tarafından kabul edilmiştir.</li>
          <li>Tur esnasında kıymetli eşya ve para kayıplarından şirketimiz sorumlu değildir. Rezervasyon Sahibi ve/veya adına rezervasyon yaptığı kişi(ler)in valizlerinin ve içindeki eşyaların her türlü sorumluluğu sahiplerine aittir. Yanında bulunan eşyaları takip ve kontrol etme yükümlülüğünde olup, tur esnasında kayıp/çalıntı/zayi olan eşyalardan acentenin veya çalışanlarının sorumluluğu yoktur. Tur aracında unutulan eşyalardan acente sorumlu tutulamaz. Unutulan eşyaların bulunmasının ve misafire ulaştırılmasının mümkün olduğu durumlarda kargo / araç / ulaşım masrafları yolcunun kendisine ait olacaktır.</li>
          <li>Otel/Tur konaklaması ile birlikte yapılan tüm havayolu bilet rezervasyonları ile TCDD bilet rezervasyonları (promosyon, ekonomi, business sınıf tüm kategoriler dahil ) ( mücbir sebep olsa dahi ) iptal şartları kapsamı dışında olup ilgili havayolu firmasının / TCDD 'nin iptal koşulları ve uygulamaları geçerlidir. Uçak bileti, tren bileti dahil alınan otel/tur iptallerinde uçak/ tren ücretleri hariç tutar üzerinden sözleşme iptal kuralları geçerli olacaktır. İptal uygulamasında uçak/tren ulaşım bedeli hariç tutulur. Rezervasyon Sahibinin uçak biletlerini paket harici şahsi temin etmeleri durumunda, ister mil / puan kullanımı ile ister direk ödeme ile alınmış olsun, Acentenin turu iptal etmesi halinde, acente doğacak zarardan sorumlu tutulamaz.</li>
          <li>Mücbir sebepler nedeni ile ve/veya özel günler, Milli ve Dini Bayramlar Döneminde turistik merkezlerde oluşacak yoğunluk nedeni ile oluşabilecek gecikmelerden Acente sorumlu tutulamaz. Bu ve benzeri durumlar ile hava durumu ve yol koşulları nedeni ile Acenta / rehber tur güzergâhında ve turda değişiklik yapma hakkına sahiptir. Değişiklikler tur öncesinde yapılabileceği gibi duruma bağlı olarak tur esnasında da yapılabilir.</li>
          <li>Tura çıkmadan veya tur esnasında ortaya çıkabilecek herhangi bir grev, siyasi olay, doğal afet, teknik olaylar, hava muhalefeti, vb durumlardan ve taşıyıcı şirketlerin yapabileceği değişikliklerden, iptallerden, gecikmelerden dolayı acentamız sorumlu tutulamaz. Buna bağlı olarak acentamız programda, ücretlerde ve nakil araçlarında her türlü değişikliği yapma hakkına sahiptir. Bu konuda sözleşme sırasında özel uyarı ve bilgilendirme yapılmış kabul edilir.</li>
          <li>Acenta turun düzenlenmesi için yeterli sayıya ulaşılamaması, mücbir sebeplerin ortaya çıkması ve ön görülmeyen, turun akışını, içeriğini, kalitesini ve güvenliğini olumsuz etkileyeceği varsayılan sebeplerin oluşması durumunda Rezervasyon Sahibine bildirmek kaydı ile turu kısmen veya tamamen iptal edebilir. Yukarıda sayılan nedenlerle, acente turu iptal ederse, turun iptal edildiğini, rezervasyon sahibine bildirir. Yapılacak olan iadeler rezervasyon esnasında alınan ödeme şekline göre yapılır. Nakit ödemelerde iade havale/eft yolu ile, kredi kartı ile yapılan ödemelerde iade ödeme yapılan kredi kartına, havale/eft ile gelen ödemelerde ise rezervasyon sahibine havale/eft yapılır. Kredi kartı iadelerinde iadenin rezervasyon sahibinin kartına yansıma süresi ilgili bankanın/finans kuruluşunun kendi işleyişiyle alakalıdır. Acente sorumlu tutulamaz.</li>
          <li>Çocuk yaşı indirimleri minimum 2 yetişkin ile aynı odada konaklamak kaydı ile geçerlidir. 1 Yetişkin yanında çocuk indirimi geçerli olmayıp, 2 yetişkin olarak hesaplanmaktadır.</li>
          <li>Konaklamalı tur kayıtlarında 3 kişilik yapılan kayıt için uygulanan 3. kişi indirimleri 3 kişilik odada konaklamak kaydıyla geçerli olup 3. kişi konaklama yatağı standart yatak olarak garanti edilmez. 3 kişilik odanın 3. yatağının ek yatak olabilmesi hususunu rezervasyon sahibi kabul etmiş sayılır.</li>
          <li>Zorunlu seyahat sigortası uygulaması yurt içi konaklamalı turlarda söz konusudur. Günübirlik turlarda veya yurt dışı turlarda sigorta Acente tarafından organize edilir. Konaklamalı turlarda bu sigorta fiyatlara dahil olup, sigorta poliçeleri, sözleşme şartlarına göre Acente tarafından yapılır. Sigorta kapsamı, genel hükümler, teminat limitleri, muafiyetler, istisnalar ile ilgili detaylı bilgiler Acentenin belirlemiş olduğu sözleşmelerde belirtilmiş olup, sigorta şirketi tarafından ve zorunlu sigorta esasları doğrultusunda yapılır. Acentenin sigorta poliçesi düzenlemesi, poliçe bedelinin ödenmesi durumunda geçerlidir. İlgili poliçeye ilişkin genel bilgiler tur öncesi Acenta tarafından bildirilecektir.</li>
        </ol>

        <p><strong>İPTAL, DEĞİŞİKLİK, HAKLAR VE YÜKÜMLÜLÜKLER:</strong></p>
        <ul>
          <li>Rezervasyon Sahibi, her türlü iptal ve değişiklik talebini acenteye yazılı olarak bildirmekle yükümlüdür.</li>
          <li>İptal ve değişiklik durumlarında, acente tarafınca belirtilen şartlar ve ücretler geçerli olacaktır. İptal ücretleri ilgili tur ve otel şartlarına göre değişiklik gösterebilir.</li>
          <li>Tur/otel iptal ve değişiklik koşulları, ilgili tur veya otelin sözleşmesinde belirtilen özel hükümlere bağlı olarak değişiklik gösterebilir.</li>
        </ul>

      </div>

    );
  };



  return (
    <div className="nc-PageSingle pt-8 lg:pt-16 ">
      <Helmet>
        <title>Esmeralda </title>
      </Helmet>
      <Heading desc="" isCenter>
        Çerezler & Gizlilik Sözleşmesi
      </Heading>

      {/* {renderHeader()} */}
      {/* <NcImage
        className="w-full max-w-xl rounded-xl"
        containerClassName="container my-10 sm:my-12 flex justify-center"
        src="https://images.unsplash.com/photo-1511578314322-379afb476865?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
      /> */}

      <div className="nc-SingleContent container space-y-10">
        {renderContent()} <br />

      </div>

    </div>
  );
};

export default BlogSingle;
