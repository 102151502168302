import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import i18n from "i18next";
import florenceJpg from "images/florence1.jpg";
import santoriniJpg from "images/santorini1.jpg";
import tokyoJpg from "images/tokyo1.jpg";
import russiaJpg from "images/russia.jpg";
import mostarJpg from "images/mostar1.jpg";
import parisJpg from "images/paris1.jpg";
import CardCategory6 from "components/CardCategory6/CardCategory6";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHeroArchivePage2 from "components/SectionHeroArchivePage/SectionHeroArchivePage2";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import { TaxonomyType } from "data/types";
import React, { FC } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";  // <-- Import useTranslation
import InternationalToursCard from "./InternationalToursCard";

export interface ListingExperiencesPageProps {
  className?: string;
}

// Demo categories
const DEMO_CATS: TaxonomyType[] = [
  {
    id: "1",
    href: "#",
    name: "Enjoy the Beauty of Brazil ",
    taxonomy: "category",
    count: 17288,
    thumbnail:
      "https://images.pexels.com/photos/1118877/pexels-photo-1118877.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
    listingType: "experiences",
  },
  {
    id: "2",
    href: "#",
    name: "Enjoy the Beauty of Paris",
    taxonomy: "category",
    count: 2118,
    thumbnail:
      "https://images.pexels.com/photos/2412609/pexels-photo-2412609.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
    listingType: "experiences",
  },
  {
    id: "3",
    href: "#",
    name: "Enjoy the Beauty of Bangkok",
    taxonomy: "category",
    count: 36612,
    thumbnail:
      "https://images.pexels.com/photos/732895/pexels-photo-732895.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
    listingType: "experiences",
  },
  {
    id: "5",
    href: "#",
    name: "Enjoy the Beauty of Singapore",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/3152124/pexels-photo-3152124.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
    listingType: "experiences",
  },
  {
    id: "4",
    href: "#",
    name: "Enjoy the Beauty of Seoul",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/373290/pexels-photo-373290.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
    listingType: "experiences",
  },
];

const DEMO_CATS_2: TaxonomyType[] = [
  {
    id: "1",
    href: "/cuba",
    name: "cuba",
    taxonomy: "category",
    count: 188288,
    thumbnail: "https://images.unsplash.com/photo-1500759285222-a95626b934cb?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    id: "2",
    href: "/morocco",
    name: "morocco",
    taxonomy: "category",
    count: 188288,
    thumbnail: "https://images.unsplash.com/photo-1526928878027-1cf28c1405ba?q=80&w=2135&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    id: "3",
    href: "",
    name: "festivals",
    taxonomy: "category",
    count: 188288,
    thumbnail: russiaJpg,
  },
  {
    id: "4",
    href: "/greece",
    name: "greece",
    taxonomy: "category",
    count: 188288,
    thumbnail: "https://images.unsplash.com/photo-1599423217192-34da246be9e8?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    id: "5",
    href: "/moscow_st_petersburg",
    name: "russia",
    taxonomy: "category",
    count: 188288,
    thumbnail: russiaJpg,
  },
  {
    id: "6",
    href: "/balkans",
    name: "balkans",
    taxonomy: "category",
    count: 188288,
    thumbnail: mostarJpg,
  },
  {
    id: "7",
    href: "/scandinavia",
    name: "scandinavia",
    taxonomy: "category",
    count: 188288,
    thumbnail: "https://images.unsplash.com/photo-1513622470522-26c3c8a854bc?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    id: "8",
    href: "",
    name: "honeymoon",
    taxonomy: "category",
    count: 0,
    thumbnail: "https://images.pexels.com/photos/3768095/pexels-photo-3768095.jpeg",
  },
  {
    id: "9",
    href: "",
    name: "hotelReservation",
    taxonomy: "category",
    count: 0,
    thumbnail: "https://images.pexels.com/photos/3768095/pexels-photo-3768095.jpeg",
  },
  {
    id: "10",
    href: "/listing-stay",
    name: "transfers",
    taxonomy: "category",
    count: 188288,
    thumbnail: "https://images.pexels.com/photos/3768095/pexels-photo-3768095.jpeg",
  },
  {
    id: "11",
    href: "",
    name: "mice",
    taxonomy: "category",
    count: 188288,
    thumbnail: "https://images.pexels.com/photos/3768095/pexels-photo-3768095.jpeg",
  },
  {
    id: "12",
    href: "",
    name: "eventOrganization",
    taxonomy: "category",
    count: 188288,
    thumbnail: "https://images.pexels.com/photos/4171419/pexels-photo-4171419.jpeg",
  },
];

const ListingExperiencesPage: FC<ListingExperiencesPageProps> = ({
  className = "",
}) => {
  const { t } = useTranslation();  // <-- Hook to use translations

  // Translate categories dynamically
  const translatedCats2 = DEMO_CATS_2.map(category => ({
    ...category,
    name: t(category.name),  // Translate category names dynamically
  }));

  return (
    <div
      className={`nc-ListingExperiencesPage relative overflow-hidden ${className}`}
      data-nc-id="ListingExperiencesPage"
    >
      <Helmet>
        <title>{t("internationalToursHelmet")}</title>  {/* Translate helmet title */}
      </Helmet>
      <BgGlassmorphism />

      <div className="container relative">
        {/* SECTION HERO */}
        <SectionHeroArchivePage2
          currentPage={t("experiences")}
          currentTab={t("experiences")}
          listingType={
            <>
              <i className="text-2xl las la-umbrella-beach"></i>
              <span className="ml-2.5"></span>
            </>
          }
          className="pt-10 pb-24 lg:pb-28 lg:pt-16 -mb-40"
        />

        {/* Grid for Categories */}
        <div className="grid grid-cols-12 gap-7 mt-10 mb-16">
          <div className="col-span-12 sm:col-span-6 lg:col-span-4 grid grid-rows-2 gap-6">
            <CardCategory6 taxonomy={translatedCats2[0]} />
            <CardCategory6 taxonomy={translatedCats2[1]} />
          </div>
          <div className="col-span-12 sm:col-span-6 lg:col-span-4 grid grid-rows-2 gap-6">
            <CardCategory6 taxonomy={translatedCats2[3]} />
            <CardCategory6 taxonomy={translatedCats2[4]} />
          </div>
          <div className="col-span-12 sm:col-span-6 lg:col-span-4 grid grid-rows-2 gap-6">
            <CardCategory6 taxonomy={translatedCats2[5]} />
            <CardCategory6 taxonomy={translatedCats2[6]} />
          </div>
        </div>

        {/* SECTION */}
        {/* <div className="relative py-16 mt-16">
          <BackgroundSection />
          <SectionSliderNewCategories
            heading={t("exploreTopDestinations")}
            subHeading={t("exploreThousandsOfDestinations")}
            categoryCardType="card4"
            itemPerRow={4}
            categories={DEMO_CATS.map((category) => ({
              ...category,
              name: t(category.name),  // <-- Translate category names dynamically
            }))}
            sliderStyle="style2"
            uniqueClassName="ListingExperiencesPage"
          />
        </div> */}

        {/* SECTION */}
        {/* <SectionSubscribe2 className="py-24 lg:py-28" /> */}
      </div>
    </div>
  );
};

export default ListingExperiencesPage;
