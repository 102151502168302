import React, { FC, ReactNode } from "react";
import i18n from "i18next";
import imagePng from "images/cappadocia1.jpg";
import HeroSearchForm, {
  SearchTab,
} from "components/HeroSearchForm/HeroSearchForm";

export interface SectionHeroArchivePageProps {
  className?: string;
  listingType?: ReactNode;
  currentPage: "Stays" | "Experiences" | "Cars" | "Flights";
  currentTab: SearchTab;
  rightImage?: string;
}

const SectionHeroArchivePage: FC<SectionHeroArchivePageProps> = ({
  className = "",
  listingType,
  currentPage,
  currentTab,
  rightImage = imagePng,
}) => {
  return (
    <div
      className={`nc-SectionHeroArchivePage flex flex-col relative ${className}`}
      data-nc-id="SectionHeroArchivePage"
    >
      <div className="flex flex-col lg:flex-row lg:items-center">
  <div className="flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-6 lg:space-y-10 pb-14 lg:pb-64 xl:pb-80 xl:pr-14 lg:mr-10 xl:mr-0">
    <h2 className="font-semibold text-3xl md:text-4xl xl:text-6xl leading-[110%]">
      {i18n.t("localTours") + "  🇹🇷"}
    </h2>
    <div className="flex items-center text-base md:text-medium text-neutral-500 dark:text-neutral-400 tracking-wide leading-relaxed">
      <span className="ml-2.5">
        <i className="text-2xl las la-compass"></i> {i18n.t("localToursParagraph")}
      </span>
    </div>
  </div>

  {/* The image will be hidden on mobile, shown on larger screens */}
  <div className="flex-grow hidden lg:block -mt-10"> {/* Negative margin here */}
    <img
      className="w-full"
      src={rightImage}
      alt="hero"
      style={{ borderRadius: '15px' }}  // Add inline style to round the corners
    />
  </div>
</div>

      <div className="hidden lg:flow-root w-full">
        <div className="z-10 lg:-mt-40 xl:-mt-56 w-full">
          {/* <HeroSearchForm currentPage={currentPage} currentTab={currentTab} /> */}
        </div>
      </div>
    </div>
  );
};

export default SectionHeroArchivePage;
