import React, { FC } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { DEMO_EXPERIENCES_LISTINGS } from "data/listings";
import { ExperiencesDataType } from "data/types";
import StartRating from "components/StartRating/StartRating";
import { Link } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import Badge from "shared/Badge/Badge";
import i18n from "i18next";  // Import i18n for translations

export interface ExperiencesCardProps {
  className?: string;
  ratioClass?: string;
  data?: ExperiencesDataType;
  size?: "default" | "small";
  translatedTitle?: string;  // This prop will hold the translated title
}

const DEMO_DATA: ExperiencesDataType = DEMO_EXPERIENCES_LISTINGS[0];

const ExperiencesCard: FC<ExperiencesCardProps> = ({
  size = "default",
  className = "",
  data = DEMO_DATA,
  ratioClass = "aspect-w-3 aspect-h-3",
  translatedTitle,  // We will use this prop for the translated title
}) => {
  const {
    galleryImgs,
    address,
    title,  // You can still keep the original title in case you need to debug or fall back
    href,
    like,
    saleOff,
    isAds,
    price,   // This will be translated
    reviewStart,
    reviewCount,
    id,
  } = data;

  // Translate the title (if needed) and the price (which can be a key)
  const translatedTitleText = translatedTitle || i18n.t(title);  // Use translated title or fallback to original title
  const translatedPriceText = i18n.t(price);  // Assuming `price` is a key for translation (e.g. "6nights7days")

  const renderSliderGallery = () => {
    return (
      <div className="relative w-full rounded-xl overflow-hidden will-change-transform">
        <GallerySlider
          uniqueID={`ExperiencesCard_${id}`}
          ratioClass={ratioClass}
          galleryImgs={galleryImgs}
          href={href}
        />
        {/* <BtnLikeIcon isLiked={like} className="absolute right-3 top-3" /> */}
        {/* {saleOff && <SaleOffBadge className="absolute left-3 top-3" />} */}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "py-4 space-y-2" : "py-3 space-y-2"}>
        <div className="flex items-center space-x-2">
          {/* {isAds && <Badge name="ADS" color="green" />} */}
          <h2
            className={`font-medium capitalize ${size === "default" ? "text-base" : "text-base"}`}
          >
            <span className="line-clamp-1">{translatedTitleText}</span>
          </h2>
        </div>

        {/* Additional content can be rendered here */}
        <div className="border-b border-neutral-100 dark:border-neutral-800"></div>
        <div className="flex justify-between items-center">
          <span className="text-base font-medium">
            {translatedPriceText}  {/* Translated price */}
            {/* Optionally, if you want to add price per person or other details, you can do so here */}
            {/* {size === "default" && (
              <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                /person
              </span>
            )} */}
          </span>
          {/* <StartRating reviewCount={reviewCount} point={reviewStart} /> */}
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-ExperiencesCard group relative ${className}`}
      data-nc-id="ExperiencesCard"
    >
      {renderSliderGallery()}
      <Link to={href}>{renderContent()}</Link>
    </div>
  );
};

export default ExperiencesCard;
